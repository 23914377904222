import { useApi } from '../api-query.context';
import { useQuery } from '@tanstack/react-query';
export function useDams() {
    const api = useApi();
    return useQuery({
        queryKey: ['user-preferences'],
        queryFn: api.user.preferences,
        select: (data) => {
            const dams = [];
            if (!data)
                return dams;
            for (const province of data.dam.choices) {
                for (const dam of province.dam) {
                    dams.push({
                        id: dam.id,
                        name: dam.name,
                        region: province.name
                    });
                }
            }
            return dams;
        }
    });
}
export function useDam(id) {
    const api = useApi();
    return useQuery({
        queryKey: ['user-preferences'],
        queryFn: api.user.preferences,
        select: (data) => {
            let dam = null;
            for (const province of data.dam.choices) {
                for (const d of province.dam) {
                    if (d.id === id) {
                        dam = d;
                        break;
                    }
                }
            }
            return dam;
        }
    });
}
export function useUserDamLevels() {
    const api = useApi();
    return useQuery({
        queryKey: ['user-dam'],
        queryFn: api.dams.levels
    });
}
