import type { FeatureCollection, Point } from 'geojson';
import { BaseGISApiModule, type GISApiOptions } from './gis-base.api-module';

export class ITESTMicroLifeModule extends BaseGISApiModule {
	constructor(opts: GISApiOptions) {
		super(opts);
	}

	reportDates = async (farmId: number) => {
		const dates = await this.http.get<Array<{ datum: string }>>(
			`/micro-life/dates/${farmId}`
		);
		return {
			farmId,
			dates,
			isITESTMicroLife: true
		};
	};

	reports = async (options: ITESTMicroLifeReportOptions) => {
		const data = await this.http.get<ITESTMicroLifeReportsResponse>(
			`/micro-life/${options.farmId}/${options.from}/${options.to}`
		);

		for (const feature of data.features) {
			for (const [i, entry] of feature.properties.entries.entries()) {
				feature.properties[`entry_${i}`] = entry.value;
				feature.properties[`entry_${i}_measurement_unit`] =
					entry.measurement_unit;
			}
		}

		return data;
	};
}

export type ITESTMicroLifeReportOptions = {
	farmId: number;
	from: string;
	to: string;
};

export interface ITESTMicroLifeReport {
	entries: ITESTMicroLifeEntry[];
	at_no: string;
	crop: string;
	cultivar: string | null;
	potential_cultivar_id: string | null;
	root_stock: string | null;
	potential_root_stock_id: string | null | number;
	plant_age_years: null | number;
	phenological_phase: null | string;
	datum: string;
	agent_recommendation: string;
	farm_id: number;
	date_created: string;
	date_updated: string | null;
	[key: `entry_${number}`]: number;
	[key: `entry_${number}_measurement_unit`]: string;
}

export interface ITESTMicroLifeEntry {
	name: string;
	value: number;
	measurement_unit: string;
	key_for_treatment: string | null;
	key_for_treatment_description: string | null;
	date_created: string;
	date_updated: string | null;
}

export type ITESTMicroLifeReportsResponse = FeatureCollection<
	Point,
	ITESTMicroLifeReport
>;
