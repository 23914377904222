import { isSoilClassificationLayer } from '@agritechnovation/api';
import { useIsFetching, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
import { useUser } from './use-user';
import { useLayerFilter } from './use-layer-filter';
import { useFarm } from './use-farm';
import { dateBucketToRange, isDateBucketString, toYYYYMMDD } from '@agritechnovation/utils';
import flatten from '@turf/flatten';
import { useMemo } from 'react';
function makeFarmLayerDataQueryKey({ farmId, layerFilterId, dates = [], dateBucket, variant, cost, sell, depth }) {
    return [
        'farm',
        farmId,
        'layer-filter',
        layerFilterId,
        'data',
        dates
            .map((x) => `${x.id}-${dateBucket ? x.date_bucket : x.date}${depth ? `-${depth}` : ''}`)
            .join(','),
        variant ?? 8,
        cost ?? 0,
        sell ?? 0
    ];
}
export function useIsFarmLayerDataFetching(opts) {
    const memoizedOpts = useMemo(() => opts, [opts]);
    const nQueries = useIsFetching({
        queryKey: makeFarmLayerDataQueryKey(memoizedOpts),
        exact: true,
        stale: false,
        fetchStatus: 'fetching'
    });
    return useMemo(() => nQueries > 0, [nQueries]);
}
export function useIsFarmLayerDataError(opts) {
    const memoizedOpts = useMemo(() => opts, [opts]);
    const query = useQuery({
        queryKey: makeFarmLayerDataQueryKey(memoizedOpts),
        enabled: false
    });
    return query.isError;
}
export const useFarmLayerData = ({ farmId, layerFilterId, dates = [], dateBucket, variant, cost, sell, depth }) => {
    const api = useApi();
    const { data: user } = useUser();
    const { data: layerFilter } = useLayerFilter(layerFilterId);
    const { data: farm } = useFarm(farmId);
    const queryKey = useMemo(() => makeFarmLayerDataQueryKey({
        farmId,
        layerFilterId,
        dates,
        dateBucket,
        variant,
        cost,
        sell,
        depth
    }), [farmId, layerFilterId, dates, dateBucket, variant, cost, sell, depth]);
    const { data, isPending } = useQuery({
        structuralSharing: false, // potentially a lot of data
        // eslint-disable-next-line @tanstack/query/exhaustive-deps -- queryKey is memoized
        queryKey,
        queryFn: async () => {
            if (!user || !farm || !layerFilter) {
                throw new Error('Missing user, farm, or layer filter');
            }
            if (layerFilter.layer_type === 'point' ||
                layerFilter.layer_type === 'pdf_point') {
                let from = dates[0].date;
                let to = dates.at(-1)?.date;
                if (dateBucket && isDateBucketString(dates[0].date_bucket)) {
                    const range = dateBucketToRange(dates[0].date_bucket);
                    from = toYYYYMMDD(range.from);
                    to = toYYYYMMDD(range.to);
                }
                else {
                    if (!from && to) {
                        from = to;
                    }
                    if (!to) {
                        to = from;
                    }
                }
                return await api.point.get({
                    projectName: farm.project_name,
                    layerFilter: layerFilter.layer_name,
                    id: dates[0].id,
                    dateFrom: from,
                    dateTo: to
                });
            }
            if (layerFilter.layer_type === 'cog') {
                return dates.map((d) => api.tms.createURL(`/${d.uuid}/raw.tiff`));
            }
            if (isYieldLayer(layerFilter) || isMyProfitabilityLayer(layerFilter)) {
                const classifications = getMyYieldClassifications(dates, {
                    variant: variant ?? 8,
                    dates: dates.map((x) => (dateBucket ? x.date_bucket : x.date)),
                    dateBucket
                });
                const featureCollections = await Promise.all(classifications.map((c) => api.geojson.get(c.uuid)));
                if (featureCollections.length === 1) {
                    return featureCollections[0];
                }
                const merged = {
                    type: 'FeatureCollection',
                    features: []
                };
                for (const featureCollection of featureCollections) {
                    for (const feature of featureCollection.features) {
                        merged.features.push(feature);
                    }
                }
                return merged;
            }
            const featureCollections = await Promise.all(dates.map((d) => api.geojson.get(d.uuid)));
            const fc = {
                type: 'FeatureCollection',
                features: featureCollections.flatMap((fc) => fc.features)
            };
            if (isSoilClassificationLayer(layerFilter)) {
                return flatten(fc);
            }
            return fc;
        },
        enabled: !!user && !!layerFilter && !!farm && dates.length > 0,
        retry: false,
        select: (data) => {
            if (isMyProfitabilityLayer(layerFilter)) {
                const { max: maxTons } = parseClassToFloatMinMax(data.features.at(-1)?.properties?.label);
                const { min: minTons } = parseClassToFloatMinMax(data.features[0]?.properties?.label);
                for (const feature of data.features) {
                    if (feature.properties) {
                        const profit = getProfitClass(feature.properties.label, {
                            maxTons,
                            minTons,
                            sellingPricePerUnit: sell ?? 0,
                            costPerHa: cost ?? 0
                        });
                        if (profit) {
                            feature.properties.profitLabel = profit.label;
                            feature.properties.profitColour = profit.color;
                        }
                    }
                }
                return data;
            }
            return data;
        }
    });
    return {
        data,
        isPending,
        enabled: !!user && !!layerFilter && !!farm && dates.length > 0
    };
};
export function isYieldLayer(layer) {
    return layer.unique_id.startsWith('yield');
}
export function isMyProfitabilityLayer(layer) {
    return layer.unique_id.startsWith('profitability');
}
export function isCogLayer(layer) {
    return layer.layer_type === 'cog';
}
export function getMyYieldClassifications(observations, opts) {
    const classifications = [];
    for (const observation of observations) {
        // this won't be a build time ts-error, but using "ts-expect-error" it will error during linting
        if (observation.has_s3_style) {
            if (opts.dateBucket) {
                classifications.push({
                    id: observation.id,
                    base_name: observation.base_name,
                    date: observation.date,
                    date_bucket: observation.date_bucket,
                    farm_id: observation.farm_id,
                    uuid: observation.variants[`Time_Bucket_Classification_16`],
                    variants: observation.variants,
                    layer_filter_id: observation.layer_filter_id,
                    has_s3_style: observation.has_s3_style,
                    itest_soil_depth_value: observation.itest_soil_depth_value,
                    itest_soil_depth_value_unit: observation.itest_soil_depth_value_unit
                });
                continue;
            }
            else {
                classifications.push({
                    id: observation.id,
                    base_name: observation.base_name,
                    date: observation.date,
                    date_bucket: observation.date_bucket,
                    farm_id: observation.farm_id,
                    uuid: observation.uuid,
                    variants: observation.variants,
                    layer_filter_id: observation.layer_filter_id,
                    has_s3_style: observation.has_s3_style,
                    itest_soil_depth_value: observation.itest_soil_depth_value,
                    itest_soil_depth_value_unit: observation.itest_soil_depth_value_unit
                });
                continue;
            }
        }
        const dateBucketKey = `Time_Bucket_Classification_${opts.variant}`;
        const dateKey = `Classification_${opts.variant}`;
        const canUseDateBucket = opts.dateBucket && dateBucketKey in observation.variants;
        let variantUuid = observation.variants[canUseDateBucket ? dateBucketKey : dateKey];
        // the case for profitability
        if (!variantUuid && opts.dateBucket) {
            const variantArray = Object.values(observation.variants);
            variantUuid = variantArray[0];
        }
        if (classifications.some((c) => c.uuid === variantUuid))
            continue;
        classifications.push({
            id: observation.id,
            base_name: observation.base_name,
            date: observation.date,
            date_bucket: observation.date_bucket,
            farm_id: observation.farm_id,
            uuid: variantUuid ?? observation.uuid,
            variants: observation.variants,
            layer_filter_id: observation.layer_filter_id,
            has_s3_style: observation.has_s3_style,
            itest_soil_depth_value: observation.itest_soil_depth_value,
            itest_soil_depth_value_unit: observation.itest_soil_depth_value
        });
    }
    const filtered = classifications.filter((c) => c.uuid && opts.dateBucket
        ? opts.dates?.includes(c.date_bucket)
        : opts.dates?.includes(c.date));
    if (filtered.length === 0) {
        if (classifications.at(-1)) {
            if (opts.dateBucket) {
                const dateBuckets = [
                    ...new Set(classifications.map((c) => c.date_bucket))
                ];
                const mostRecentDateBucket = dateBuckets.at(-1);
                if (mostRecentDateBucket) {
                    return classifications.filter((c) => c.date_bucket === mostRecentDateBucket);
                }
            }
            return [classifications.at(-1)];
        }
    }
    return classifications;
}
export const lossColors = [
    { label: 'Very High Loss', color: '#b30000' },
    { label: 'High Loss', color: '#e60000' },
    { label: 'Medium Loss', color: '#ff1a1a' },
    { label: 'Small Loss', color: '#ff4d4d' }
];
export const profitColors = [
    { label: 'Small Profit', color: '#79de6e' },
    { label: 'Medium Profit', color: '#3fd02f' },
    { label: 'High Profit', color: '#2c9321' },
    { label: 'Very High Profit', color: '#195313' }
];
export const fullProfitColors = [
    ...lossColors,
    {
        label: 'Break Even',
        color: '#000000'
    },
    ...profitColors
];
export function parseClassToFloatMinMax(label) {
    if (!label) {
        return {
            min: 0,
            max: 0
        };
    }
    if (!label.includes('-')) {
        return {
            min: parseFloat(label),
            max: parseFloat(label)
        };
    }
    const [min, max] = label.split('-');
    return {
        min: parseFloat(min),
        max: parseFloat(max)
    };
}
export function getProfitClass(label, { maxTons, minTons, sellingPricePerUnit, costPerHa }) {
    if (costPerHa === 0 && sellingPricePerUnit === 0) {
        return {
            label: 'Break Even',
            color: '#000000'
        };
    }
    const maxProfit = maxTons * sellingPricePerUnit - costPerHa;
    const minProfit = minTons * sellingPricePerUnit - costPerHa;
    const lostStep = Math.abs(minProfit / lossColors.length);
    const profitStep = Math.abs(maxProfit / profitColors.length);
    const { max, min } = parseClassToFloatMinMax(label);
    const profitMax = Math.floor(max * sellingPricePerUnit - costPerHa);
    const profitMin = Math.floor(min * sellingPricePerUnit - costPerHa);
    if (profitMin <= 0 && 0 <= profitMax) {
        return {
            label: 'Break Even',
            color: '#000000'
        };
    }
    else if (profitMin > 0) {
        const index = Math.floor(profitMin / profitStep);
        return profitColors[index];
    }
    else {
        const index = Math.floor(Math.abs(profitMax) / lostStep);
        return lossColors[index];
    }
}
