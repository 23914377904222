import type {
	UserPreferencesResponse,
	UserPreferencesUpdateArgs
} from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class UserApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	preferences = async () => {
		return this.http.get<UserPreferencesResponse>('/user/preferences');
	};
	updatePreferences = async ({ dam, ...data }: UserPreferencesUpdateArgs) => {
		const serializedDams = {
			...data,
			dam: JSON.stringify(dam.map((d) => String(d)))
		};
		return this.http.post<'OK'>('/user/preferences', serializedDams);
	};
}
