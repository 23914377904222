import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import type { ITokenStorage } from '../../token-storage';
import { BaseApiModule } from '../base.api-module';

export type GISApiOptions = {
	domain: string;
	language: string;
	tokenStorage: ITokenStorage;
	authErrorHandler: AuthErrorHandler;
	limiter?: Limiter;
};
export abstract class BaseGISApiModule extends BaseApiModule {
	base: string;
	constructor({
		domain,
		language,
		tokenStorage,
		limiter,
		authErrorHandler
	}: GISApiOptions) {
		const base = `${domain}/${language}/api/5.0.0/gis/api`;
		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
	}
}
