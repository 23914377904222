import type {
	FilterGroup,
	FilterGroupsForAppMenu,
	LayerFilter
} from '../../api.types';

const NO_DATA_VALUES = ['no data', 'beyond drill range', 'no', 'nee', 'none'];

export function isNoDataValue(value?: string | number) {
	if (!value) return false;
	if (typeof value === 'number') return false;
	return NO_DATA_VALUES.includes(value.toLowerCase());
}

export function isPPMLayer(layer: LayerFilter) {
	return layer.filter_group === 'PPM';
}

export function isSoilClassificationLayer(layer: LayerFilter) {
	return layer.filter_group === 'My Soil Classification';
}

export function isRecommendationLayer(layer: LayerFilter) {
	return layer.full_name.toLowerCase().includes('recommendation');
}

export function isMyIrrigationLayer(layer: LayerFilter) {
	return layer.filter_group === 'My Irrigation';
}

export function isITESTSoilLayer(layer: LayerFilter) {
	return layer.filter_group === 'ITEST Soil';
}

export function isSmartLayer(layer: LayerFilter) {
	return layer.filter_group === 'My Smart Layer';
}

export function isITESTCarbohydatesFilterGroup(
	filterGroup: FilterGroupsForAppMenu | FilterGroup
) {
	return filterGroup.unique_id === 'itest_carbohydrates(0)';
}

export function isITESTMicroLifeFilterGroup(
	filterGroup: FilterGroupsForAppMenu | FilterGroup
) {
	return filterGroup.unique_id === 'itest_microlife(0)';
}

export function isITESTMicroLifeLayer(layer: LayerFilter) {
	return layer.filter_group === 'ITEST Microlife';
}

export function isITESTCarbohydatesLayer(layer: LayerFilter) {
	return layer.filter_group === 'ITEST Carbohydrates';
}
