import { queryOptions } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function usePPMCropsOptions() {
    const api = useApi();
    return queryOptions({
        queryKey: ['ppm-crops'],
        queryFn: api.ppm.crops,
        retry: 3
    });
}
