import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
import { useMemo } from 'react';
import { mergeFeatureCollections } from '@agritechnovation/utils';
export function useAvailableCentroids(availableLayerId, enabled = true) {
    const api = useApi();
    return useQuery({
        queryKey: ['available-layer-centroids', availableLayerId],
        queryFn: () => {
            if (!availableLayerId)
                throw new Error('No available layer id provided');
            return api.gis.centroids(availableLayerId);
        },
        enabled: enabled && !!availableLayerId,
        retry: 3
    });
}
export function useMultipleAvailableCentroids(availableLayerIds, enabled = true) {
    const api = useApi();
    const queries = useQueries({
        queries: availableLayerIds.map((id) => {
            return queryOptions({
                queryKey: ['available-layer-centroids', id],
                queryFn: () => api.gis.centroids(id),
                select: (data) => {
                    data.features = data.features.filter((f) => !!f.properties?.value);
                    return data;
                },
                enabled,
                retry: 3
            });
        })
    });
    return useMemo(() => {
        return {
            data: mergeFeatureCollections(...queries
                .map((q) => q.data)
                .filter(Boolean)),
            isPending: queries.some((q) => q.isPending),
            isError: queries.some((q) => q.isError),
            isFetching: queries.some((q) => q.isFetching),
            isSuccess: queries.every((q) => q.isSuccess),
            error: queries.map((q) => q.error).filter(Boolean)
        };
    }, [queries]);
}
