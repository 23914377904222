import { queryOptions, useQuery } from '@tanstack/react-query';
import { useUser } from './use-user';
import { useApi } from '../api-query.context';
export function useFarms(select) {
    const api = useApi();
    const { data: user } = useUser();
    return useQuery({
        queryKey: ['farms'],
        queryFn: async () => {
            return await api.farms.list();
        },
        enabled: !!user,
        select
    });
}
export function farmsQueryOptions(api) {
    return queryOptions({
        queryKey: ['farms'],
        queryFn: async () => {
            return await api.farms.list();
        }
    });
}
