import { HTTPClient } from '../../http-client';
import { UnAuthenticatedBaseApiModule } from '../base.api-module';

const hosts = {
	'dev.myfarmweb.com': 'dev.myfarmweb.com',
	localhost: 'myfarmweb.com',
	'myfarmweb.com': 'myfarmweb.com',
	'us.myfarmweb.com': 'us.myfarmweb.com',
	'www.myfarmweb.com': 'myfarmweb.com',
	'cf-test-dev.myfarmweb.com': 'myfarmweb.com'
} as const;

type MFWStaticHosts = typeof hosts;

export type MFWStaticHost = MFWStaticHosts[keyof MFWStaticHosts];

export abstract class BaseStaticApi<
	T extends MFWStaticHost
> extends UnAuthenticatedBaseApiModule {
	private domain: T;
	constructor({ domain }: { domain: T }) {
		const http = new HTTPClient(BaseStaticApi.getBase(domain));
		super(http);
		this.domain = domain;
	}

	get base() {
		return BaseStaticApi.getBase(this.domain);
	}

	static getBase = <B extends MFWStaticHost>(domain: B) => {
		const host = new URL(domain).host as keyof MFWStaticHosts;
		const cloudfrontHost = hosts[host];
		return `https://${cloudfrontHost}/static-data` as const;
	};
}
