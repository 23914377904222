import { format, parse } from 'date-fns';
import type {
	SmartLayerFormResponse,
	SmartLayerRecord,
	SmartLayerResult,
	SmartLayerType
} from '../../api.types';
import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import { BaseApiModule } from '../base.api-module';
import type { BoundaryApiModule } from '../boundary';
import type { ITokenStorage } from '../../token-storage';

export type SmartLayerApiOptions = {
	domain: string;
	language: string;
	tokenStorage: ITokenStorage;
	authErrorHandler: AuthErrorHandler;
	limiter?: Limiter;
	boundary: BoundaryApiModule;
};

export class SmartLayerApiModule extends BaseApiModule {
	base: string;
	boundary: BoundaryApiModule;
	constructor({
		domain,
		language,
		tokenStorage,
		limiter,
		boundary,
		authErrorHandler
	}: SmartLayerApiOptions) {
		const base = `${domain}/${language}/api/5.0.0/simple-layer/api`;
		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
		this.boundary = boundary;
	}

	farm = (id: number) => {
		return this.boundary.smartLayer(id);
	};
	listByBlock = async (id: number) => {
		const types = ['perennial-crop', 'annual-crop', 'irrigation'] as const;
		const getSmartLayer = async <T extends (typeof types)[number]>(
			smartLayerType: (typeof types)[number]
		): Promise<SmartLayerResult<T>> => {
			if (!types.includes(smartLayerType)) {
				throw new Error('Invalid smart layer type');
			}
			return {
				type: smartLayerType as T,
				result: await this.http.get(
					`/${smartLayerType}/list?precise=true&page=1&subblocks=${id}`
				)
			};
		};
		return await Promise.all(types.map((type) => getSmartLayer(type)));
	};
	form = () => {
		return this.http.get<SmartLayerFormResponse>('/data/simple-layer-form', {
			reBasePath: this.base.replace('/simple-layer/api', '/agri/api')
		});
	};
	update = async (type: SmartLayerType, data: SmartLayerRecord) => {
		const body = new FormData();
		const excludeFields = [
			'block_name',
			'total_production',
			'name',
			'date_indicator',
			'farm',
			'captured_by',
			'captured_from',
			'yield_per_ha',
			'decommissioned',
			'orchard performance (/10)'
		];
		for (const [key, value] of Object.entries(data)) {
			if (excludeFields.includes(key)) continue;
			if (key === 'captured_at') continue;
			if (Array.isArray(value)) {
				if (key === 'sub_blocks') {
					body.append('subblocks', String((value as number[])[0]));
				} else {
					body.append(key, JSON.stringify(value));
				}
			} else {
				if (value === null) {
					body.append(key, '');
				} else if (key.includes('date')) {
					if (value && typeof value === 'string') {
						const parsed = parse(value.slice(0, 10), 'yyyy-MM-dd', new Date());
						body.append(key, format(parsed, 'yyyy-MM-dd HH:mm:ss'));
					}
				} else {
					body.append(key, String(value));
				}
			}
		}
		const rawResult = await this.http.post<string>(`/${type}/upload`, body);
		const result = JSON.parse(rawResult) as {
			uuid: string;
			success: boolean;
			data: SmartLayerRecord;
		};
		if (!result.success) {
			throw new Error('Failed to upload');
		}
		let updated = false;
		while (!updated) {
			const updateStatus = await this.http
				.get<string>(`/check-available-layer-script?uuid=${result.uuid}`, {
					reBasePath: this.base.replace('/simple-layer/api', '/agri/api')
				})
				.catch((e: unknown) => {
					if (HTTPClient.isHTTPError(e) && e.status !== 404) {
						throw e;
					}
				});
			if (updateStatus === 'finished') {
				updated = true;
			}
		}
		return result.data;
	};
}
