import type { FeatureCollection, Point, Polygon } from 'geojson';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class SoilClassificationApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}
	labels = async (availableLayerId: number) => {
		return this.http.get<FeatureCollection<Point>>(
			`/my-soil-classification/labels?id=${availableLayerId}`
		);
	};
	/**
	 * Returns an entire 'surface' of soil classification data
	 */
	polygon = async (farmId: number, datum: string) => {
		const params = new URLSearchParams();
		params.append('farm_id', String(farmId));
		params.append('datum', datum);
		return this.http.get<FeatureCollection<Polygon>>(
			'/my-soil-classification/soil-polygon',
			{
				params: {
					farm_id: farmId,
					datum: datum
				}
			}
		);
	};
	/**
	 * Returns the actual geometry to define the area where soil classification data was ordered
	 */
	mapPoly = async (
		availableLayerId: number,
		opts: {
			farms: number[];
			dateBucketed: boolean;
		}
	) => {
		return this.http.get<FeatureCollection<Polygon>>(
			`/my-soil-classification/map-polygon`,
			{
				params: {
					farms: opts.farms.join(','),
					bucket: opts.dateBucketed,
					id: availableLayerId
				}
			}
		);
	};
}
