import type {
	DrilldownDataPoint,
	LayerTimeLineDataPoint
} from '../../api.types';
import type { GISApiOptions } from './gis-base.api-module';
import { BaseGISApiModule } from './gis-base.api-module';
import type { URLPath } from '../../http-client';

export class DrilldownApiModule extends BaseGISApiModule {
	constructor(opts: GISApiOptions) {
		super(opts);
	}

	feature = async (
		farmId: number,
		drillDownType: string,
		dataType: string,
		opts: { coordinates: [number, number] | number[][]; blockId?: number }
	) => {
		let finalDrillDownType =
			drillDownType === 'polygon' ? 'geometry' : drillDownType;
		if (drillDownType === 'block' || drillDownType === 'sub-block') {
			finalDrillDownType = 'block';
		}
		const url: URLPath =
			dataType !== 'mysoilclassification'
				? `/location-data/${dataType}/${finalDrillDownType}`
				: `/location-information`;
		const params: Record<string, string> = {};
		if (drillDownType === 'polygon') {
			params['geom'] = JSON.stringify({
				type: 'Polygon',
				coordinates: opts.coordinates
			});
		}
		if (drillDownType === 'point') {
			params['lat'] = String(opts.coordinates[1]);
			params['lng'] = String(opts.coordinates[0]);
		}
		if (drillDownType === 'block' || drillDownType === 'sub-block') {
			params['id'] = String(opts.blockId);
		}
		params['farms'] = String(farmId);
		params['data-type'] = dataType || '';

		const allData = await this.http.get<DrilldownDataPoint[]>(url, { params });
		if (!this.useRasterDrilldown(dataType)) {
			return allData;
		} else {
			params['use_tiff'] = 'true';
			const rasterData = await this.http.get<DrilldownDataPoint[]>(url, {
				params
			});
			return this.dedupeRasterData(allData, rasterData);
		}
	};

	private dedupeRasterData(
		vectorBased: DrilldownDataPoint[],
		rasterBased: DrilldownDataPoint[]
	) {
		const dedupedData = rasterBased;
		const rasterLayerFilterIds = rasterBased.map((d) => d.layer_filter_id);
		for (const vectorData of vectorBased) {
			if (!rasterLayerFilterIds.includes(vectorData.layer_filter_id)) {
				dedupedData.push(vectorData);
			}
		}
		return dedupedData;
	}

	private useRasterDrilldown(dataType: string) {
		// return ['itestsoilsurfaces', 'yieldsurfaces', 'ppm'].includes(dataType);
		return dataType === 'yieldsurfaces';
	}

	timeline = async (
		farmId: number,
		drillDownType: string,
		layerFilterId: number,
		opts: { coordinates?: [number, number] | number[][]; blockId?: number }
	) => {
		const params: Record<string, string> = {};
		if (drillDownType === 'point') {
			params['lat'] = String(opts.coordinates?.[1]);
			params['lng'] = String(opts.coordinates?.[0]);
		}
		if (drillDownType === 'polygon') {
			params['geometry'] = JSON.stringify({
				type: 'Polygon',
				coordinates: opts.coordinates
			});
		}
		if (drillDownType === 'block' || drillDownType === 'sub-block') {
			params['block_id'] = String(opts.blockId);
		}
		params['farm_id'] = String(farmId);
		params['layer_filter_id'] = String(layerFilterId);
		return this.http.get<LayerTimeLineDataPoint[]>('/layer-timeline', {
			params
		});
	};
}
