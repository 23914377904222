import { useQuery, keepPreviousData, queryOptions } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function createPPMBlocksQueryKey({ farmId, date_bucket, crop_id, observation_method_id }) {
    return [
        'ppm-blocks-geojson',
        farmId,
        date_bucket,
        crop_id,
        observation_method_id
    ];
}
export function createPPMBlocksQuery(api, { farmId, date_bucket, crop_id, observation_method_id }) {
    return queryOptions({
        queryKey: [
            'ppm-blocks-geojson',
            farmId,
            date_bucket,
            crop_id,
            observation_method_id
        ],
        queryFn: () => {
            if (!farmId || !date_bucket)
                throw new Error('farmId and date are required');
            return api.ppm.blocksGeojson(farmId, {
                date_bucket,
                crop_id,
                observation_method_id
            });
        }
    });
}
export function usePPMBlocksGEOJSONByDateBucket({ farmId, date_bucket, crop_id, observation_method_id }, opts) {
    const api = useApi();
    return useQuery({
        queryKey: [
            'ppm-blocks-geojson',
            farmId,
            date_bucket,
            crop_id,
            observation_method_id
        ],
        queryFn: () => {
            if (!farmId || !date_bucket)
                throw new Error('farmId and date are required');
            return api.ppm.blocksGeojson(farmId, {
                date_bucket,
                crop_id,
                observation_method_id
            });
        },
        enabled: !!farmId && !!date_bucket && !opts?.wait,
        placeholderData: keepPreviousData
    });
}
