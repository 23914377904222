import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function usePPMObservationOptions() {
    const api = useApi();
    return queryOptions({
        queryKey: ['ppm-observation-methods'],
        queryFn: api.ppm.observationMethods
    });
}
export function usePPMObservationMethods() {
    const options = usePPMObservationOptions();
    return useQuery(options);
}
export function usePPMObservationMethodByName(name) {
    const options = usePPMObservationOptions();
    return useQuery({
        ...options,
        select: (data) => data.find((method) => method.observation_method === name),
        enabled: !!name
    });
}
