import type { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import { BaseApiModule } from '../base.api-module';
import type { BoundaryApiModule } from '../boundary';
import type {
	FeatureCollectionWithProperties,
	PPMTrapFeatures
} from '../../api.types';
import type { ITokenStorage } from '../../token-storage';

export type PPMApiOptions = {
	domain: string;
	language: string;
	tokenStorage: ITokenStorage;
	limiter?: Limiter;
	boundary: BoundaryApiModule;
	authErrorHandler: AuthErrorHandler;
};

export class PPMApiModule extends BaseApiModule {
	base: string;
	boundary: BoundaryApiModule;
	constructor({
		domain,
		language,
		tokenStorage,
		limiter,
		boundary
	}: PPMApiOptions) {
		const base = `${domain}/${language}/api/5.0.0/ppm/api`;
		const http = new HTTPClient(base, {
			limiter
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
		this.boundary = boundary;
	}
	detail = (id: number) => {
		return this.http.get(`/ppm-layer-filter-details/${id}`);
	};

	trapsGraph = async (availableLayerId: number) => {
		const result = await this.http.get<PPMTrapFeatures>(
			`/ppm-traps-graph?id=${availableLayerId}`
		);
		const fc: PPMTrapFeatures = {
			type: 'FeatureCollection',
			features: []
		};
		/**
		 * Please refer to https://myfarmweb.atlassian.net/browse/MFW-5870
		 * as to why we have to deduplicate the features.................. :)
		 */
		for (const feat of result.features) {
			const exisistingFeature = fc.features.find((f) => {
				return (
					f.geometry.coordinates[0] === feat.geometry.coordinates[0] &&
					f.geometry.coordinates[1] === feat.geometry.coordinates[1]
				);
			});
			if (exisistingFeature) {
				if (
					exisistingFeature.properties.value === null &&
					feat.properties.value !== null
				) {
					// remove the existing feature
					fc.features = fc.features.filter((f) => f !== exisistingFeature);
					fc.features.push(feat);
				}
				continue;
			}
			fc.features.push(feat);
		}
		return fc;
	};

	trapsDownload = (availableLayerObservationId: number): Promise<unknown> => {
		return this.http.get<Blob>(
			`/ppm-traps-download?id=${availableLayerObservationId}`,
			{
				mime: 'blob'
			}
		);
	};

	trapsData = (
		{
			farms,
			to,
			from,
			layerFilterIds
		}: {
			farms: Array<number>;
			layerFilterIds: Array<number>;
			to: string;
			from: string;
		},
		signal?: AbortSignal
	) => {
		return this.http.get<PPMGraphDataResponse>(`/ppm-traps-data`, {
			params: {
				to,
				from,
				'layer-filters': layerFilterIds.join(','),
				farms: farms.join(',')
			},
			abort: signal
		});
	};

	listData = (
		{
			farms,
			start,
			end
		}: {
			farms: Array<number>;
			start: string;
			end: string;
		},
		signal?: AbortSignal
	) => {
		return this.http.get<PPMDataListResponse>(
			`/list-ppm-data/${start}/${end}`,
			{
				params: {
					farms: farms.join(',')
				},
				abort: signal
			}
		);
	};

	crops = async () => {
		return this.http.get<
			Array<{
				id: number;
				crop: string;
			}>
		>('/ppm-crop');
	};

	observationMethods = async () => {
		return this.http.get<PPMObservationMethodResponse>(
			'/ppm-observation-method'
		);
	};

	blocksGeojson = async (
		farmId: number,
		opts:
			| { date: string }
			| {
					date_bucket: string;
					crop_id?: number;
					observation_method_id?: number;
			  }
	): Promise<FeatureCollection<Polygon | MultiPolygon>> => {
		return this.boundary.ppmBlocks(farmId, opts);
	};

	summary = async ({ farmId, method, from, to }: PPMSummaryOptions) => {
		const data = await this.http.get<PPMSummaryResponse>(
			`/ppm-sum-layer/${farmId}/${method}/${from}/${to}`
		);
		data.properties = {
			farmId,
			method,
			from,
			to
		};
		return data;
	};

	static GRAPH_DATA_HEADERS = [
		'PUC',
		'Farm ID',
		'Farm Name',
		'Contact Person',
		'Contact Number',
		'Commodity',
		'Cultivar',
		'Trap Number',
		'Latitude',
		'Longitude',
		'Date Count',
		'Date Captured',
		'Date Rebait',
		'Count Trap',
		'Data Source',
		'Count Fruit Infestation',
		'Species',
		'Sex',
		'Type of Trap',
		'Type of Lure'
	] as const;
}

export type PPMHeader = PPMHeaders[number];

export type PPMHeaders = Array<
	(typeof PPMApiModule.GRAPH_DATA_HEADERS)[number]
>;

export type PPMSummaryResponse = FeatureCollectionWithProperties<
	PPMSummaryOptions,
	Point,
	PPMSummaryPointAttributes
>;

export type PPMObservationMethodResponse = Array<{
	id: number;
	observation_method: PPMObservationMethod;
}>;

export type PPMGraphDataResponse = {
	headers: PPMHeaders;
	data: string[][];
};

export type PPMObservationMethod =
	| 'Trap'
	| 'Scouting'
	| 'Non-Pest-Related'
	| 'Scouting-Study'
	| 'Quantitative-Method'
	| 'Traps-Study'; // if additional methods are added to the API, they should be added here

export type PPMSummaryOptions = {
	farmId: number;
	method: PPMObservationMethod;
	from: string;
	to: string;
};

export type PPMSummaryPointAttributes = {
	crop: string | null;
	field_number: string | null;
	capture_point_name: string | null;
	counts: Array<{
		count: number;
		name: string; // pest name
		layer_filter_id: number;
	}>;
};

export type PPMDataListResponse = Array<PPMDataListDataPoint>;

export type PPMDataListPestCount = {
	name: string;
	value: number;
};

export interface PPMDataListDataPoint {
	point_x: number;
	point_y: number;
	property_id: number;
	name: string;
	puc: string;
	notes_for_office: string;
	capture_date: string;
	capture_time: string;
	gps_activity: string;
	field_number: string;
	lokvalnommer: string;
	app_user: string;
	photo_1: string;
	photo_2: string;
	photo_3: string;
	photo_4: string;
	photo_5: string;
	remarks: string;
	datum: string;
	pests: PPMDataListPestCount[];
	observation_method: string;
}

export type PPMCrop = {
	id: number;
	crop: string;
};
