import type { FeatureCollection } from 'geojson';
import type { RequestConfig, URLPath } from '../../http-client';
import type { MFWStaticHost } from './base-static.api-module';
import { BaseStaticApi } from './base-static.api-module';

export class GeoJSONStaticApiModule<
	T extends MFWStaticHost
> extends BaseStaticApi<T> {
	constructor({ domain }: { domain: T }) {
		super({
			domain
		});
	}
	/**
	 * Warning, use this direcly only if you are confident that the geojson returned is valid
	 */
	get = async <T extends FeatureCollection = FeatureCollection>(
		path: URLPath,
		cfg?: RequestConfig
	) => {
		return this.http.get<T>(`/geojson${path}`, {
			mime: 'json',
			...cfg
		});
	};
}
