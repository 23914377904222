import { useApi } from '../api-query.context';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
export function usePpmTrapsGraph(availableLayerId) {
    const api = useApi();
    return useQuery({
        queryKey: ['ppm-traps-graph', availableLayerId],
        queryFn: () => {
            if (!availableLayerId) {
                throw new Error('Missing available layer id');
            }
            return api.ppm.trapsGraph(availableLayerId);
        },
        enabled: !!availableLayerId
    });
}
export function useMultiplePPMTrapsGraph(availableLayerIds) {
    const api = useApi();
    const queries = useQueries({
        queries: availableLayerIds.map((id) => ({
            queryKey: ['ppm-traps-graph', id],
            queryFn: () => {
                return api.ppm.trapsGraph(id);
            }
        }))
    });
    const isPending = useMemo(() => {
        return queries.some((q) => q.isPending);
    }, [queries]);
    const data = useMemo(() => {
        const fc = {
            type: 'FeatureCollection',
            features: []
        };
        for (const q of queries) {
            if (q.data) {
                fc.features.push(...q.data.features);
            }
        }
        return fc;
    }, [queries]);
    return {
        isPending,
        data
    };
}
