import type {
	CreateRequestOpts,
	HTTPClient,
	RequestConfig
} from '../http-client';
import type {
	ITokenStorage,
	TokenKey,
	TokenStorageListenerArgs
} from '../token-storage';

export type AllowedFetchOptions = Pick<RequestConfig, 'abort'>;

export abstract class BaseApiModule {
	protected http: HTTPClient;
	protected tokenStorage: ITokenStorage;
	protected tokenKey: TokenKey;
	protected constructor(opts: {
		http: HTTPClient;
		tokenStorage: ITokenStorage;
		tokenKey: TokenKey;
	}) {
		this.http = opts.http;
		this.tokenStorage = opts.tokenStorage;
		this.tokenKey = opts.tokenKey;
		const listener = this.onTokenChange.bind(this);
		this.tokenStorage.listen(listener);
		this.http.onBeforeRequest = this.onBeforeRequest.bind(this);
	}
	protected async onBeforeRequest(_: HTTPClient, opts: CreateRequestOpts) {
		const all = await this.tokenStorage.all();
		this.setToken(all[this.tokenKey as keyof TokenStorageListenerArgs]);
		return opts;
	}
	protected onTokenChange(opts: TokenStorageListenerArgs) {
		this.setToken(opts[this.tokenKey as keyof TokenStorageListenerArgs]);
	}

	setToken(token: string | null) {
		if (token === null) this.http.auth(null);
		this.http.auth(`Token ${token}`);
	}
}

export abstract class UnAuthenticatedBaseApiModule {
	protected http: HTTPClient;
	protected constructor(http: HTTPClient) {
		this.http = http;
	}
}
