import Bottleneck from 'bottleneck';
import {
	AssetsApiModule,
	AuthApi,
	AuthRefreshTokenError,
	AuthenticationError,
	AvailableLayersApiModule,
	BoundaryApiModule,
	ClientsApiModule,
	DamsApiModule,
	DrilldownApiModule,
	FarmsApiModule,
	FilesApiModule,
	FilterGroupsApiModule,
	GISApiModule,
	GeoJSONApiModule,
	GeoJSONStaticApiModule,
	ITESTCarbohydratesModule,
	ITESTMicroLifeModule,
	InfrastructureApiModule,
	IrrigationApiModule,
	LayerFilterApiModule,
	MediaStaticApiModule,
	PPMApiModule,
	PointDataApiModule,
	SmartLayerApiModule,
	SoilClassificationApiModule,
	TMSApiModule,
	UserApiModule,
	WeatherApiModule
} from './modules';
import type { Limiter } from './http-client/limiter';
import { BookmarksApiModule } from './modules/bookmarks';
import type { AuthErrorHandler, HTTPError } from './http-client';
import { HTTPClient } from './http-client';
import type { MFWStaticHost } from './modules/static/base-static.api-module';
import { BaseStaticApi } from './modules/static/base-static.api-module';
import type { ITokenStorage } from './token-storage';
import { Milliseconds } from '@agritechnovation/utils';
import { CropApiModule } from './modules/crops/crops.api-module';
import { AppManagerModule } from './modules/app-manager/app-manager.api-module';
export type {
	PPMSummaryOptions,
	PPMSummaryPointAttributes,
	PPMSummaryResponse,
	RawLegendEntry,
	RawLegend,
	PPMHeaders,
	PPMHeader,
	PPMGraphDataResponse,
	PPMDataListDataPoint,
	PPMDataListResponse
} from './modules';

export type LimiterOptions = {
	main?: {
		maxConcurrent: number;
		minTime: number;
	};
	boundary?: {
		maxConcurrent: number;
		minTime: number;
	};
};

export class Api {
	boundaryLimiter: Limiter;
	mainLimiter: Limiter;
	boundary: BoundaryApiModule;
	tms: TMSApiModule;
	/**
	 *	This module is used to access the static geojson files, but it does not perform any additional operations on the data
	 */
	staticGeoJSON: GeoJSONStaticApiModule<MFWStaticHost>;

	appManager: AppManagerModule;
	media: MediaStaticApiModule;
	auth: AuthApi;
	user: UserApiModule;
	farms: FarmsApiModule;
	filters: FilterGroupsApiModule;
	layers: LayerFilterApiModule;
	availableLayers: AvailableLayersApiModule;
	/**
	 * This geojson module is used to access the static geojson files, but it performs additional operations on the data
	 */
	geojson: GeoJSONApiModule;
	point: PointDataApiModule;
	soilclassification: SoilClassificationApiModule;
	drilldown: DrilldownApiModule;
	weather: WeatherApiModule;
	gis: GISApiModule;
	clients: ClientsApiModule;
	irrigation: IrrigationApiModule;
	assets: AssetsApiModule;
	infrastructure: InfrastructureApiModule;
	dams: DamsApiModule;
	smartLayers: SmartLayerApiModule;
	bookmarks: BookmarksApiModule;
	ppm: PPMApiModule;
	files: FilesApiModule;
	microLife: ITESTMicroLifeModule;
	carbohydrates: ITESTCarbohydratesModule;
	crops: CropApiModule;

	constructor(
		private domain: string,
		private language: string,
		private tokenStorage: ITokenStorage,
		private appName?: string,
		private disableAuthErrorHandler = false,
		limiterOptions?: LimiterOptions
	) {
		this.mainLimiter = new Bottleneck({
			maxConcurrent: limiterOptions?.main?.maxConcurrent ?? 25,
			minTime: limiterOptions?.main?.minTime ?? 100
		});

		this.boundaryLimiter = new Bottleneck({
			maxConcurrent: limiterOptions?.boundary?.maxConcurrent ?? 10,
			minTime: limiterOptions?.boundary?.minTime ?? 200
		});

		this.boundary = new BoundaryApiModule(this.boundaryOpts);

		this.tms = new TMSApiModule(this.staticOpts);
		this.staticGeoJSON = new GeoJSONStaticApiModule(this.staticOpts);
		this.media = new MediaStaticApiModule(this.staticOpts);

		this.crops = new CropApiModule(this.tokenStorage, this.domain);
		this.appManager = new AppManagerModule(this.mainOpts);
		this.auth = new AuthApi({
			...this.mainOpts,
			crops: this.crops,
			appManager: this.appManager
		});

		this.user = new UserApiModule(this.mainOpts);
		this.farms = new FarmsApiModule(this.mainOpts);
		this.filters = new FilterGroupsApiModule({
			...this.mainOpts,
			media: this.media
		});
		this.layers = new LayerFilterApiModule(this.mainOpts);
		this.availableLayers = new AvailableLayersApiModule({
			...this.mainOpts,
			tms: this.tms
		});
		this.geojson = new GeoJSONApiModule({
			...this.mainOpts,
			geojson: this.staticGeoJSON
		});
		this.point = new PointDataApiModule(this.mainOpts);
		this.gis = new GISApiModule(this.mainOpts);
		this.soilclassification = new SoilClassificationApiModule(this.mainOpts);
		this.drilldown = new DrilldownApiModule(this.mainOpts);
		this.microLife = new ITESTMicroLifeModule(this.mainOpts);
		this.carbohydrates = new ITESTCarbohydratesModule(this.mainOpts);
		this.weather = new WeatherApiModule(this.mainOpts);
		this.clients = new ClientsApiModule(this.mainOpts);
		this.filters = new FilterGroupsApiModule({
			...this.mainOpts,
			media: this.media
		});
		this.files = new FilesApiModule(this.mainOpts);
		this.irrigation = new IrrigationApiModule(this.mainOpts);
		this.assets = new AssetsApiModule(this.mainOpts);
		this.infrastructure = new InfrastructureApiModule(this.mainOpts);
		this.dams = new DamsApiModule(this.mainOpts);
		this.smartLayers = new SmartLayerApiModule({
			...this.mainOpts,
			boundary: this.boundary
		});
		this.bookmarks = new BookmarksApiModule(this.mainOpts);
		this.ppm = new PPMApiModule({
			...this.mainOpts,
			boundary: this.boundary
		});
	}

	get mainOpts() {
		return {
			domain: this.domain,
			appName: this.appName,
			language: this.language,
			limiter: this.mainLimiter,
			tokenStorage: this.tokenStorage,
			authErrorHandler: this.authErrorHandler
		};
	}
	get boundaryOpts() {
		return {
			domain: this.domain,
			limiter: this.boundaryLimiter,
			tokenStorage: this.tokenStorage,
			authErrorHandler: this.authErrorHandler
		};
	}
	get staticOpts() {
		return {
			domain: this.domain as MFWStaticHost
		};
	}

	/*
	 * The http client will call this when a requests fails with 401
	 * This function will attempt to refresh the token and retry the request
	 * If the token cannot be refreshed, it will throw an error
	 */
	authErrorHandler: AuthErrorHandler = async (http, result) => {
		// for testing purposes, we can disable the auth error handler
		if (this.disableAuthErrorHandler) {
			return {
				...result,
				options: {
					...result.options,
					cfg: {
						...result.options.cfg,
						excludeAuthErrorHandling: true
					}
				}
			};
		}
		if (result.url.includes('token-refresh')) {
			await this.tokenStorage.setIsRefreshing(false);
			throw new AuthRefreshTokenError('Failed to refresh token');
		}
		const isRefreshing = await this.tokenStorage.isRefreshing();
		if (isRefreshing) {
			let timeout = Milliseconds.minutes(1);
			while (await this.tokenStorage.isRefreshing()) {
				await new Promise((resolve) => setTimeout(resolve, 100));
				timeout -= 100;
				if (timeout <= 0) {
					await this.tokenStorage.setIsRefreshing(false);
					throw new AuthRefreshTokenError('Auth error handler timeout');
				}
			}
			return http.retry(result);
		}
		await this.tokenStorage.setIsRefreshing(true);
		const refreshToken = await this.tokenStorage.getRefreshToken();
		if (!refreshToken) {
			await this.tokenStorage.setIsRefreshing(false);
			throw new AuthRefreshTokenError('No refresh token');
		}
		try {
			await this.auth.refreshToken();
		} catch {
			throw new AuthRefreshTokenError('Failed to refresh token');
		} finally {
			await this.tokenStorage.setIsRefreshing(false);
		}
		return http.retry(result);
	};

	authErrorHandlerIsRunning = false;

	isLoggedOutError(
		error: unknown
	): error is AuthenticationError | AuthRefreshTokenError | HTTPError {
		if (HTTPClient.isHTTPError(error)) {
			if (error.response.url) {
				// since static apis (S3) sometimes return 403, we need to check the url is not for a static resource
				const staticBase = BaseStaticApi.getBase(this.domain as MFWStaticHost);
				if (!error.response.url.startsWith(staticBase)) {
					const status = error.response.status;
					const isAuth = status === 401 || status === 403;
					return isAuth;
				}
			}
		}
		return (
			error instanceof AuthenticationError ||
			error instanceof AuthRefreshTokenError
		);
	}

	isHTTPError = HTTPClient.isHTTPError;
}
