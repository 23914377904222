import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import type { FeatureCollectionWithProperties } from '../api.types';
import { roundNumber } from '@agritechnovation/utils';

export type FCWithAreaProperties = FeatureCollectionWithProperties<
	{
		stats: {
			area: {
				ac: number;
				ha: number;
			};
		};
	},
	Polygon | MultiPolygon
>;

export function addAreaProperties<
	T extends FeatureCollection<Polygon | MultiPolygon>
>(fc: T): FCWithAreaProperties {
	if (!fc) {
		return fc;
	}
	if (!fc.features) {
		const withProperties = fc as unknown as FCWithAreaProperties;
		withProperties.properties = {
			...(withProperties?.properties ?? {}),
			stats: {
				area: {
					ha: 0,
					ac: 0
				}
			}
		};
		return withProperties;
	}
	const totalAreas = {
		ha: 0,
		ac: 0
	};
	for (const feature of fc.features) {
		if (feature.properties?.area) {
			feature.properties.area_ha = roundNumber(
				feature.properties.area / 10000,
				2
			);
			totalAreas.ha += feature.properties.area_ha;
			feature.properties.area_ac = roundNumber(
				feature.properties.area / 4046.856,
				2
			);
			totalAreas.ac += feature.properties.area_ac;
		}
		// PREVIOUS_TODO: we can remove this in release of ol 9.0.0 currently we are on "ol": "8.2.0",
		// cannot remove this as it will possibly break area calculation in some parts of the app
		if (feature.geometry.type === 'MultiPolygon') {
			if (feature.geometry.coordinates.length === 0) {
				feature.geometry.coordinates = [[[[]]]];
			}
		}
		if (feature.geometry.type === 'Polygon') {
			if (feature.geometry.coordinates.length === 0) {
				feature.geometry.coordinates = [[[]]];
			}
		}
	}
	const withProperties = fc as unknown as FCWithAreaProperties;
	withProperties.properties = {
		...(withProperties.properties ?? {}),
		stats: {
			area: totalAreas
		}
	};
	return withProperties;
}
