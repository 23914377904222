import { queryOptions, useQuery } from '@tanstack/react-query';
import { useUser } from './use-user';
import { useApi } from '../api-query.context';
export function useFarmBoundaryGeojson(id) {
    const api = useApi();
    const { data: user } = useUser();
    return useQuery({
        ...farmBoundaryQueryOptions(api, id),
        enabled: !!user
    });
}
export function farmBoundaryQueryOptions(api, id) {
    return queryOptions({
        queryKey: ['farm', id, 'boundary'],
        queryFn: ({ signal }) => api.boundary.farm(id, { abort: signal })
    });
}
export function farmBoundaryGeojsonQueryFN(api, id) {
    return ({ signal }) => {
        return api.boundary.farm(id, { abort: signal });
    };
}
export function farmBoundaryQueryKey(id) {
    return ['farm', id, 'boundary'];
}
