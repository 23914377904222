import type { CreateRequestOpts } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type {
	ITokenStorage,
	TokenStorageListenerArgs
} from '../../token-storage';

export class CropApiModule {
	tokenKey = 'cropServiceToken';
	url = import.meta.env.USE_STAGING_API
		? 'https://staging-crop.agritechnovation.com/v1/croprepository'
		: 'https://crop.agritechnovation.com/v1/croprepository';
	http = new HTTPClient(this.url);

	constructor(
		private tokenStorage: ITokenStorage,
		private verifyDomain: string
	) {
		this.http.onBeforeRequest = this.onBeforeRequest.bind(this);
		const listener = this.onTokenChange.bind(this);
		this.tokenStorage.listen(listener);
		this.http.onBeforeRequest = this.onBeforeRequest.bind(this);
	}

	protected async onBeforeRequest(_: HTTPClient, opts: CreateRequestOpts) {
		const all = await this.tokenStorage.all();
		this.setToken(all[this.tokenKey as keyof TokenStorageListenerArgs]);
		return opts;
	}

	protected onTokenChange(opts: TokenStorageListenerArgs) {
		this.setToken(opts[this.tokenKey as keyof TokenStorageListenerArgs]);
	}

	async tokenInfo() {
		return this.http.get('/token-info');
	}

	async login(username: string, token: string) {
		const response = await this.http.post<{ token: string }>(
			'/login',
			{ username },
			{
				headers: {
					'X-REMOTE-TOKEN': `${token}`,
					'X-VERIFY-DOMAIN': this.verifyDomain
				}
			}
		);
		this.tokenStorage.setCropServiceToken(response.token);
		this.setToken(response.token);
	}

	setToken(token: string | null) {
		if (token === null) this.http.auth(null);
		this.http.auth(`Token ${token}`);
	}

	getCrop(id: number) {
		return this.http.get<{
			id: number;
			name_en: string;
			name_af: string;
			name_es: string;
		}>(`/crop/${id}/all-languages`);
	}

	getPlantSubGroup(id: number) {
		return this.http.get<{
			id: number;
			name_en: string;
			name_af: string;
			name_es: string;
		}>(`/plant-sub-group/${id}/all-languages`);
	}
}
