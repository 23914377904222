import type { FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import type { InfrastructureType } from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class InfrastructureApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	types = () =>
		this.http.get<Array<InfrastructureType>>('/infrastructure/type');

	list = (type: string, farmId: number) =>
		this.http.get<FeatureCollection<Point | Polygon | MultiPolygon>>(
			`/infrastructure/list/${type}?farm=${farmId}`
		);

	create = (opts: {
		type: InfrastructureType['name'];
		name: string;
		additional_info: string;
		farm: number;
		geometry: FeatureCollection;
	}) => {
		return this.http.postForm('/infrastructure/create', {
			...opts,
			last_serviced_date: '',
			geometry: JSON.stringify(opts.geometry)
		});
	};
}
