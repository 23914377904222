import { useUser } from './use-user';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useApi } from '../api-query.context';
export function useAvailableLayersForMultipleFarms(ids, layerFilterId) {
    const api = useApi();
    const { data: user } = useUser();
    const queries = useQueries({
        queries: ids.map((id) => ({
            queryKey: ['available-dates', id, layerFilterId],
            queryFn: async () => api.availableLayers.byFarmAndLayerFilterId(id, layerFilterId),
            enabled: !!user
        }))
    });
    return useMemo(() => {
        const results = queries.map((q) => q.data).filter((d) => d);
        if (results.length === 0)
            return [];
        return results.flat();
    }, [queries]);
}
