import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function useAquacheckInfo(id, opts) {
    const api = useApi();
    return useQuery({
        queryKey: ['aquacheck', id, ...Object.values(opts)],
        queryFn: () => {
            return api.irrigation.aquacheck(id, opts);
        }
    });
}
