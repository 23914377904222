import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function useFarmInfrastructure(typeName, farmId) {
    const api = useApi();
    return useQuery({
        queryKey: ['infastructure', farmId, typeName],
        queryFn: () => api.infrastructure.list(typeName, farmId),
        enabled: !!farmId && !!typeName
    });
}
