import type { FarmFile } from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class FilesApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	get = (featureId: number, availableLayerId: number) => {
		return this.http.get<Array<FarmFile | string>>(`/get-pdf-list`, {
			params: {
				id: featureId.toString(),
				available_layer_id: availableLayerId.toString()
			}
		});
	};

	template = async () => {
		const templates = await this.http.get<
			[
				{
					id: number;
					is_default: boolean;
					name: string;
					template: unknown;
				}
			]
		>('/list-pdf-template');
		return templates[0];
	};

	farmReport = async (
		id: string,
		report: {
			title: string;
			description: string;
			latlng: [number, number];
			utc_seconds: number;
			template_id: 1;
			images: string[];
			block_name: string | null;
			captured_by_user_uuid: string;
			last_updated: number;
		}
	) => {
		return this.http.put<FarmFile>(`/farm-pdf-report/${id}`, report);
	};
}
