import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function createInfrastructureQuery(api) {
    return queryOptions({
        queryKey: ['infrastructure-types'],
        queryFn: () => api.infrastructure.types()
    });
}
export function useInfrastructureTypes() {
    const api = useApi();
    return useQuery(createInfrastructureQuery(api));
}
export function useInfrastructureType(typeId) {
    const api = useApi();
    return useQuery({
        ...createInfrastructureQuery(api),
        select: (types) => types.find((t) => t.id === typeId)
    });
}
