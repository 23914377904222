import { useApi } from '../api-query.context';
import { useUser } from './use-user';
import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
export function useFarmBlocks(farmId) {
    const api = useApi();
    const { data: user } = useUser();
    return useQuery({
        ...farmBlocksQueryOptions(api, farmId),
        enabled: !!user && !!farmId
    });
}
export function farmBlocksQueryOptions(api, id) {
    return queryOptions({
        queryKey: ['farm', id, 'blocks'],
        queryFn: ({ signal }) => api.boundary.blocks(id, {
            abort: signal
        })
    });
}
export function useMultipleFarmsBlocksSummary(farmIds) {
    const api = useApi();
    const { data: user } = useUser();
    return useQueries({
        queries: farmIds.map((id) => ({
            ...farmBlocksQueryOptions(api, id),
            enabled: !!user
        })),
        combine: (results) => {
            let totalHA = 0;
            let totalAC = 0;
            const isLoading = results.some((r) => r.isLoading);
            if (isLoading) {
                return {
                    isLoading
                };
            }
            for (const r of results) {
                if (r.data) {
                    totalHA += r.data.properties.stats.area.ha || 0;
                    totalAC += r.data.properties.stats.area.ac || 0;
                }
            }
            return {
                isLoading: false,
                area: {
                    ac: totalAC,
                    ha: totalHA
                }
            };
        }
    });
}
export function farmBlocksQuery(api, farmId) {
    return {
        queryKey: ['farm-blocks', farmId],
        queryFn: () => api.boundary.blocks(farmId)
    };
}
