import type { LayerFilter } from '../../api.types';
import { isPPMLayer, translatePPMLayerFilter } from '../../helpers';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class LayerFilterApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	listByFilterGroupID = async (filterGroupId: number, abort?: AbortSignal) =>
		this.http.get<LayerFilter[]>(`/layer-filter`, {
			params: {
				filter_group_id: filterGroupId
			},
			abort
		});
	listByFilterGroupName = async (filterGroupName: string) =>
		this.http.get<LayerFilter[]>(`/layer-filter`, {
			params: {
				'filter-group': filterGroupName
			}
		});
	get = async (id: number, abort?: AbortSignal) => {
		const result = await this.http.get<LayerFilter>(`/layer-filter/${id}`, {
			abort
		});
		if (isPPMLayer(result)) {
			return translatePPMLayerFilter(result);
		}
		return result;
	};

	archived = async (farmId: number) =>
		this.http.get<ArchivedLayerFilter[]>(`/archieve-layer-filters/${farmId}`);
}

export type ArchivedLayerFilter = {
	layer_filter_id: number;
	farm_id: number;
};
