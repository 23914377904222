import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function ITESTCarbohydratesDatesQuery(api, farmId) {
    return queryOptions({
        queryKey: ['itest-carbohydrates', 'dates', farmId],
        queryFn: () => api.carbohydrates.reportDates(farmId)
    });
}
export function useITESTCarbohydratesDates(farmId) {
    const api = useApi();
    return useQuery(ITESTCarbohydratesDatesQuery(api, farmId));
}
export function useITESTCarbohydratesReports(options) {
    const api = useApi();
    return useQuery({
        queryKey: [
            'itest-carbohydrates',
            'reports',
            options.farmId,
            options.from,
            options.to
        ],
        queryFn: () => api.carbohydrates.reports({
            farmId: options.farmId,
            from: options.from,
            to: options.to
        }),
        enabled: !!options.from && !!options.to
    });
}
