export async function getQueryResult(queryClient, key, fn) {
    const queryData = queryClient.getQueryData(key);
    if (queryData) {
        return queryData;
    }
    return await queryClient.fetchQuery({
        queryKey: key,
        queryFn: fn
    });
}
