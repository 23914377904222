import { parse } from 'date-fns';
import type {
	AquaCheckInfoOptions,
	AquacheckInformation,
	IrricheckInformation
} from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class IrrigationApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}
	aquacheck = async (aquacheck_id: number, options: AquaCheckInfoOptions) => {
		const params = new URLSearchParams(
			options as unknown as Record<string, string>
		);
		const response = await this.http.get<AquacheckInformation[]>(
			`/aquacheck/information?${params.toString()}&aquacheck_id=${aquacheck_id}`
		);
		for (const point of response) {
			// 2023/11/14 23:30
			const date = parse(point.datum, 'yyyy/MM/dd HH:mm', new Date());
			point.timestamp = date.getTime();
			point.defTop = Number(point.value.def_top.value);
			point.defBottom = Number(point.value.def_bottom.value);
			point.defMiddle = Number(point.value.def_middle.value);
		}
		return response;
	};
	irricheck = async (
		irricheck_id: string, // from the feature id
		options: {
			from: number; // unix timestamp
			to: number; // unix timestamp
		}
	) => {
		const params = new URLSearchParams(
			options as unknown as Record<string, string>
		);
		const data = await this.http.get<IrricheckInformation[]>(
			`/irricheck-information?${params.toString()}&irricheck_id=${irricheck_id}`
		);
		for (const point of data) {
			point.timestamp = new Date(point.time).getTime();
		}
		return data;
	};
}
