import { isObject } from '@agritechnovation/utils';
import type { Limiter } from './limiter';

export type RequestConfig = {
	// override the mime type, e.g. endpoint advertises text but returns json
	mime?: 'json' | 'text' | 'blob';
	fetch?: RequestInit;
	params?: Record<string, number | string | boolean | undefined>;
	headers?: HeadersInit;
	abort?: AbortController | AbortSignal;
	reBasePath?: string;
	excludeAuthErrorHandling?: boolean;
};

export type URLPath = `/${string}` | '';

export type AuthErrorHandler = (
	client: HTTPClient,
	result: RequestResult
) => Promise<RequestResult>;

export type OnBeforeRequest = (
	client: HTTPClient,
	options: CreateRequestOpts
) => Promise<CreateRequestOpts>;

export type CreateRequestOpts = {
	path: URLPath;
	body?: unknown | undefined;
	cfg?: RequestConfig | undefined;
};

type RequestResult = FetchOptions & {
	response: Response;
};

export type FetchOptions = {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD';
	config: RequestInit;
	options: CreateRequestOpts;
};

export class HTTPClient {
	private headers: Record<string, string>;
	private limiter: Limiter | null = null;
	private authErrorHandler?: AuthErrorHandler;
	public onBeforeRequest?: OnBeforeRequest;
	constructor(
		private base: string,
		{
			headers,
			limiter,
			authErrorHandler,
			onBeforeRequest
		}: {
			headers?: Record<string, string>;
			limiter?: Limiter;
			authErrorHandler?: AuthErrorHandler;
			onBeforeRequest?: OnBeforeRequest;
		} = {}
	) {
		this.headers = headers || {};
		this.authErrorHandler = authErrorHandler;
		this.onBeforeRequest = onBeforeRequest;

		if (limiter) {
			this.limiter = limiter;
		}
	}

	public setBase = (base: string) => {
		this.base = base;
	};

	public auth = (header: string | null) => {
		if (!header) {
			delete this.headers.Authorization;
		} else {
			this.headers.Authorization = header;
		}
	};

	private parseConfigHeaders = (cfg?: RequestConfig) => {
		if (!cfg) return this.headers;
		if (!cfg.headers) return this.headers;
		if (cfg.headers instanceof Headers) {
			return {
				...(Object.fromEntries(cfg.headers.entries()) as Record<
					string,
					string
				>),
				...this.headers
			};
		}
		return { ...(cfg.headers as Record<string, string>), ...this.headers };
	};

	private createRequest = async (
		method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
		_opts: CreateRequestOpts
	) => {
		let body: BodyInit | undefined;
		let opts = _opts;
		if (this.onBeforeRequest) {
			opts = await this.onBeforeRequest(this, opts);
		}
		const headers: Record<string, string> = {
			...this.parseConfigHeaders(opts.cfg),
			'Content-Type': 'application/json'
		};
		if (opts.body) {
			if (isObject(opts.body) && !(opts.body instanceof FormData)) {
				body = JSON.stringify(opts.body);
			} else {
				body = opts.body as BodyInit;
			}
			if (opts.body instanceof FormData) {
				if (headers['Content-Type'] === 'application/json') {
					delete headers['Content-Type'];
				}
			}
		}
		if (!body && opts.cfg?.fetch?.body) {
			body = opts.cfg.fetch.body;
		}
		const url = this.createURL(opts.path, opts.cfg);
		const config = {
			method,
			body,
			headers: headers,
			mode: opts.cfg?.fetch?.mode || 'cors',
			credentials: opts.cfg?.fetch?.credentials || 'omit',
			signal:
				opts.cfg?.abort instanceof AbortController
					? opts.cfg?.abort?.signal
					: opts.cfg?.abort instanceof AbortSignal
						? opts.cfg?.abort
						: undefined
		};
		return {
			url,
			method,
			config,
			options: opts
		};
	};

	retry = async (result: RequestResult) => {
		const config = await this.createRequest(result.method, result.options);
		return this.fetch(config);
	};

	fetch = async (options: FetchOptions): Promise<RequestResult> => {
		if (this.limiter) {
			const response = await this.limiter.schedule(() =>
				fetch(options.url, options.config)
			);
			return {
				response,
				...options
			};
		}
		const response = await fetch(options.url, options.config);
		return {
			...options,
			response
		};
	};

	private createURL = (path: URLPath, cfg?: RequestConfig) => {
		let url: URL;
		if (cfg?.reBasePath) {
			url = new URL(`${cfg.reBasePath}${path}`);
		} else {
			url = new URL(`${this.base}${path}`);
		}
		if (!cfg?.params) return url.toString();
		const searchParams = new URLSearchParams();
		for (const key in cfg.params) {
			const val = cfg.params[key];
			if (val === undefined) continue;
			searchParams.append(key, val.toString());
		}
		url.search = searchParams.toString();
		return url.toString();
	};

	get = async <T>(path: URLPath, cfg?: RequestConfig): Promise<T> => {
		const req = await this.fetch(
			await this.createRequest('GET', { path, cfg })
		);
		return this.parseResponse<T>(req);
	};

	delete = async <T>(path: URLPath, cfg?: RequestConfig): Promise<T> => {
		const response = await this.fetch(
			await this.createRequest('DELETE', { path, cfg })
		);
		return this.parseResponse<T>(response);
	};

	put = async <T>(
		path: URLPath,
		body: unknown,
		cfg?: RequestConfig
	): Promise<T> => {
		const response = await this.fetch(
			await this.createRequest('PUT', { path, body, cfg })
		);
		return this.parseResponse<T>(response);
	};

	patch = async <T>(
		path: URLPath,
		body: unknown,
		cfg?: RequestConfig
	): Promise<T> => {
		const response = await this.fetch(
			await this.createRequest('PATCH', { path, body, cfg })
		);
		return this.parseResponse<T>(response);
	};

	post = async <T>(
		path: URLPath,
		body: unknown,
		cfg?: RequestConfig
	): Promise<T> => {
		const response = await this.fetch(
			await this.createRequest('POST', { path, body, cfg })
		);
		return this.parseResponse<T>(response);
	};

	postForm = async <T>(
		path: URLPath,
		body: Record<string, unknown>,
		cfg?: RequestConfig
	): Promise<T> => {
		const formData = new FormData();
		for (const key in body as Record<string, unknown>) {
			const val = (body as Record<string, unknown>)[key];
			if (val instanceof Blob) {
				formData.append(key, val);
				continue;
			}
			formData.append(key, (body as Record<string, string>)[key].toString());
		}
		const response = await this.fetch(
			await this.createRequest('POST', {
				path,
				body: formData,
				cfg
			})
		);
		return this.parseResponse<T>(response);
	};

	head = async (path: URLPath, cfg?: RequestConfig): Promise<Headers> => {
		const response = await this.fetch(
			await this.createRequest('HEAD', { path, cfg })
		);
		if (response.response.ok) {
			return response.response.headers;
		}
		throw new HTTPError({
			message: 'Failed to fetch headers',
			status: response.response.status,
			response: response.response,
			data: null
		});
	};

	private parseResponse = async <T>(result: RequestResult): Promise<T> => {
		const { response, options } = result;
		if (!response.ok) {
			if (
				response.status === 401 &&
				this.authErrorHandler &&
				!options.cfg?.excludeAuthErrorHandling
			) {
				const newResponse = await this.authErrorHandler(this, result);
				return this.parseResponse<T>(newResponse);
			}
			const message = await HTTPClient.tryParseError(response);
			throw new HTTPError({
				message:
					typeof message === 'string'
						? message
						: HTTPClient.buildGenericError(response),
				status: response.status,
				response,
				data: message
			});
		}
		if (options.cfg?.mime === 'blob') {
			return response.blob() as unknown as T;
		}
		const json =
			options.cfg?.mime === 'json' || HTTPClient.isMimeJSONLike(response);
		if (json) {
			try {
				return response.json() as T;
			} catch (e) {
				console.warn('Failed to parse JSON, returning text instead');
				return response.text() as T;
			}
		} else {
			return response.text() as T;
		}
	};

	private static buildGenericError = (response: Response) => {
		return `Req failed to ${response.url} with status ${response.status} ${response.statusText}`;
	};

	private static tryParseError = async <T>(response: Response) => {
		const cloned = response.clone();
		try {
			const json = await response.json();
			return json as T;
		} catch (e) {
			return cloned.text();
		}
	};

	static isMimeJSONLike = (res: Response) => {
		const mime = res.headers.get('content-type');
		if (!mime) return false;
		return /^application\/.*json.*/.test(mime);
	};

	static isHTTPError = (error: unknown): error is HTTPError => {
		return error instanceof HTTPError;
	};
}

export class HTTPError extends Error {
	response: Response;
	status: number;
	data: unknown;
	constructor({
		message,
		status,
		response,
		data
	}: {
		message: string;
		status: number;
		response: Response;
		data: unknown;
	}) {
		super(message);
		this.status = status;
		this.response = response;
		this.data = data;
	}
}
