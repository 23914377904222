import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function createPPMSummaryOptions(api, options) {
    return queryOptions({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [
            'ppm-summary',
            options.farmId,
            options.method,
            options.from,
            options.to
        ],
        queryFn: () => api.ppm.summary(options)
    });
}
export function usePPMSummaryOptions(api, options) {
    return createPPMSummaryOptions(api, options);
}
export function usePPMSummary(options) {
    const api = useApi();
    return useQuery(createPPMSummaryOptions(api, options));
}
