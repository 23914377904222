import { useUser } from './use-user';
import { useApi } from '../api-query.context';
import { useQuery } from '@tanstack/react-query';
export function useFarmSubBlocks(id) {
    const { data: user } = useUser();
    const api = useApi();
    return useQuery({
        queryKey: ['farm-sub-blocks', id],
        queryFn: () => api.boundary.subBlocks(id),
        enabled: !!user
    });
}
