import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export const useUser = () => {
    const api = useApi();
    return useQuery({
        queryKey: ['user'],
        queryFn: api.auth.user,
        retry: false,
        refetchOnMount: false
    });
};
export const useFirebaseCredentials = (location) => {
    const { data: user } = useUser();
    const api = useApi();
    return useQuery({
        queryKey: ['firebase', 'user', location],
        queryFn: api.appManager.firebaseCredentials,
        enabled: !!user
    });
};
