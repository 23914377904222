import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function useArchivedLayerFilters(farmId) {
    const api = useApi();
    return useQuery(createArchivedLayerFiltersQuery(api, farmId));
}
export function createArchivedLayerFiltersQuery(api, farmId) {
    return queryOptions({
        queryKey: ['layer-filters', 'archived', farmId],
        queryFn: () => api.layers.archived(farmId),
        enabled: !!farmId
    });
}
export function useArchivedLayerFiltersForManyFarms(farms) {
    const api = useApi();
    return useQueries({
        queries: farms.map((f) => createArchivedLayerFiltersQuery(api, f)),
        combine: (results) => {
            const data = results
                .map((r) => r.data)
                .flat()
                .filter(Boolean);
            const isPending = results.some((r) => r.isPending);
            return {
                data,
                isPending
            };
        }
    });
}
