import type {
	LoginOptions,
	LoginResponse,
	RefreshTokenAuthResponse,
	UserDetailResponse
} from '../../api.types';
import type { AppManagerModule } from '../app-manager/app-manager.api-module';
import type { CropApiModule } from '../crops/crops.api-module';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class AuthApi extends BaseAgriApiModule {
	#crops: CropApiModule;
	#appManager: AppManagerModule;
	constructor(
		opts: AgriModuleOptions & {
			appManager: AppManagerModule;
			crops: CropApiModule;
		}
	) {
		super(opts);
		this.#crops = opts.crops;
		this.#appManager = opts.appManager;
	}

	login = async (data: LoginOptions) => {
		const response = await this.http.post<LoginResponse>('/token-auth', {
			username: data.username,
			password: data.password,
			token_type: data.refresh ? 'renewable' : 'non-renewable',
			app_name: data.app_name
		});
		// don't disrupt the login process if crops login fails, due to potential auth implementation issues
		await this.#crops.login(data.username, response.token).catch(() => {});
		await this.tokenStorage.setToken(response.token);
		if (data.refresh && response.refresh_token) {
			await this.tokenStorage.setRefreshToken(response.refresh_token);
		}
		await this.tokenStorage.setUsername(data.username);
		await this.user();
		return response;
	};

	user = async () => {
		const token = await this.tokenStorage.getToken();
		if (!token) throw new AuthenticationError();
		this.setToken(token);
		try {
			const data = await this.http.get<UserDetailResponse>('/user-detail', {
				mime: 'json'
			});
			// this will notify boundary token listeners
			await this.tokenStorage.setBoundaryToken(data.boundary_token);
			await this.tokenStorage.setUsername(data.username);
			return data;
		} catch (e) {
			await this.tokenStorage.setToken(null);
			await this.tokenStorage.setRefreshToken(null);
			throw new AuthenticationError();
		}
	};

	refreshToken = async () => {
		const token = await this.tokenStorage.getToken();
		const refreshToken = await this.tokenStorage.getRefreshToken();
		const username = await this.tokenStorage.getUsername();
		if (!refreshToken) throw new AuthRefreshTokenError('No refresh token');
		if (!token) throw new AuthRefreshTokenError('No token');
		if (!username) throw new AuthRefreshTokenError('No username');

		try {
			const response = await this.http.post<RefreshTokenAuthResponse>(
				'/token-refresh',
				{
					token,
					refresh_token: refreshToken,
					username,
					app_name: this.appName ?? 'Unknown'
				},
				{
					excludeAuthErrorHandling: true
				}
			);
			await this.tokenStorage.setToken(response.token);
			await this.tokenStorage.setRefreshToken(response.refresh_token);
			await this.user();
			await this.#crops.login(username, response.token).catch(() => {});
		} catch (e: unknown) {
			throw new AuthRefreshTokenError(
				e instanceof Error ? e.message : 'Unknown Token Refresh Error',
				e instanceof Error ? e : undefined
			);
		}
	};

	logout = async () => {
		const token = await this.tokenStorage.getToken();
		await this.tokenStorage.setToken(null);
		await this.tokenStorage.setRefreshToken(null);
		await this.tokenStorage.setBoundaryToken(null);
		await this.tokenStorage.setUsername(null);
		await this.http.post('/token-logout', {
			token: token
		});
	};
}

export class AuthenticationError extends Error {
	constructor(
		message = 'Authentication Error',
		public originalError?: Error
	) {
		super(message);
	}
}

// clients will need to handle this error differently from other errors
export class AuthRefreshTokenError extends Error {
	constructor(
		message = 'Failed to refresh token',
		public originalError?: Error
	) {
		super(message);
	}
}
