import type { RequestConfig, URLPath } from '../../http-client';
import type { MFWStaticHost } from './base-static.api-module';
import { BaseStaticApi } from './base-static.api-module';

export class MediaStaticApiModule extends BaseStaticApi<MFWStaticHost> {
	constructor(opts: { domain: MFWStaticHost }) {
		super(opts);
	}

	get = async <T>(path: URLPath, cfg?: RequestConfig) => {
		return this.http.get<T>(`/media${path}`, cfg);
	};
}
