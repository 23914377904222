import { useUser } from './use-user';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
import { Milliseconds } from '@agritechnovation/utils';
import { makeITESTSoilDepthKey } from './use-farms-available-layers';
export function createFarmAvailableLayersQuery(api, opts) {
    return queryOptions({
        queryKey: [
            'farm',
            opts.farmId,
            'layerFilter',
            opts.layerFilterId,
            'available'
        ],
        queryFn: async () => api.availableLayers.byFarmAndLayerFilterId(opts.farmId, opts.layerFilterId),
        refetchInterval: Milliseconds.minutes(20),
        enabled: opts.enabled
    });
}
export function useFarmAvailableLayers(farmId, layerFilterId) {
    const api = useApi();
    const { data: user } = useUser();
    return useQuery(createFarmAvailableLayersQuery(api, {
        farmId,
        layerFilterId,
        enabled: !!user
    }));
}
export function groupFarmAvailableLayersByDepth(layers) {
    const grouped = new Map();
    const withDepth = layers.filter((l) => l.itest_soil_depth_value);
    const withoutDepth = layers.filter((l) => !l.itest_soil_depth_value);
    for (const layer of withDepth) {
        const depthKey = makeITESTSoilDepthKey(layer.itest_soil_depth_value, layer.itest_soil_depth_value_unit);
        if (!depthKey) {
            continue;
        }
        const group = grouped.get(depthKey) ?? [];
        group.push(layer);
        grouped.set(depthKey, group);
    }
    return {
        grouped,
        withoutDepth
    };
}
