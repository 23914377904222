import type { FeatureCollection, Point } from 'geojson';
import { BaseGISApiModule, type GISApiOptions } from './gis-base.api-module';
import { roundNumber } from '@agritechnovation/utils';

export class ITESTCarbohydratesModule extends BaseGISApiModule {
	constructor(opts: GISApiOptions) {
		super(opts);
	}

	reportDates = async (farmId: number) => {
		const dates = await this.http.get<Array<{ datum: string }>>(
			`/itest-carbohydrate/report/dates/${farmId}`
		);
		return {
			farmId,
			dates,
			isITESTCarbohydates: true
		};
	};

	reports = async (options: ITESTCarbohydratesReportOptions) => {
		const data = await this.http.get<ITESTCarbohydratesReportResponse>(
			`/itest-carbohydrate/report/${options.farmId}/${options.from}/${options.to}?force_entire_season=true`
		);
		return this.makeCustomRenderable(data);
	};

	private makeCustomRenderable(
		report: ITESTCarbohydratesReportResponse
	): ITESTCarbohydratesReportResponse {
		for (const feature of report.features) {
			for (const organ of feature.properties.organs) {
				for (const entry of organ.entries) {
					const sugarKey = `${organ.organ}_${entry.growth_stage.replaceAll(' ', '')}_sugar`;
					const starchKey = `${organ.organ}_${entry.growth_stage.replaceAll(' ', '')}_starch`;
					const totalKey = `${organ.organ}_${entry.growth_stage.replaceAll(' ', '')}_total`;

					const data = {
						[sugarKey]: roundNumber(entry.sugar, 2),
						[`${sugarKey}_min`]: roundNumber(entry.min_sugar, 2),
						[`${sugarKey}_max`]: roundNumber(entry.max_sugar, 2),
						[starchKey]: roundNumber(entry.starch, 2),
						[`${starchKey}_min`]: roundNumber(entry.min_starch, 2),
						[`${starchKey}_max`]: roundNumber(entry.max_starch, 2),
						[totalKey]: roundNumber(entry.sugar + entry.starch, 2),
						[`${totalKey}_min`]: roundNumber(
							entry.min_sugar + entry.min_starch,
							2
						),
						[`${totalKey}_max`]: roundNumber(
							entry.max_sugar + entry.max_starch,
							2
						)
					};
					feature.properties = {
						...feature.properties,
						...data
					};
				}
			}
		}
		return report;
	}
}

export type ITESTCarbohydratesReportOptions = {
	farmId: number;
	from: string;
	to: string;
};

export type ITESTCarbohydratesEntry = {
	growth_stage: string;
	sample_number: string;
	min_sugar: number;
	max_sugar: number;
	sugar: number;
	min_starch: number;
	max_starch: number;
	starch: number;
	group: number;
	datum: string;
	page_number: number;
	created_at: string;
	updated_at: string;
};

export type ITESTCarbohydratesOrganItem = {
	organ: string;
	entries: Array<ITESTCarbohydratesEntry>;
};

export type ITESTCarbohydratesReport = {
	agriculturalist: string;
	agent: string;
	crop: string;
	cultivar: string;
	field_block_number: string;
	growth_stages: Array<string>;
	farm_id: number;
	created_at: string;
	updated_at: string;
	organs: Array<ITESTCarbohydratesOrganItem>;
};

export type ITESTCarbohydratesReportResponse = FeatureCollection<
	Point,
	ITESTCarbohydratesReport
>;
