import type { Dam, DamLevel } from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class DamsApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	list = () => {
		return this.http.get<Array<Dam>>('/dam?page=1');
	};

	details = (name: string) => {
		return this.http.get<Dam>(`/dam/${name}`);
	};

	levels = () => {
		return this.http.get<Array<DamLevel>>('/user-dam');
	};
}
