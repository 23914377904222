import type { FeatureCollection } from 'geojson';
import type { MFWStaticHost } from '../static/base-static.api-module';
import type { GeoJSONStaticApiModule } from '../static/geojson-static.api-module';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';
import type { AllowedFetchOptions } from '../base.api-module';

export class GeoJSONApiModule extends BaseAgriApiModule {
	geojson: GeoJSONStaticApiModule<MFWStaticHost>;
	constructor(
		opts: AgriModuleOptions & { geojson: GeoJSONStaticApiModule<MFWStaticHost> }
	) {
		super(opts);
		this.geojson = opts.geojson;
	}

	/**
	 * Gets geojson data from s3 but also:
	 *	- Fixes "empty" geometries that will crash turf and other geo libraries
	 *	- Makes sure that "label" and "legend" properties are both set (data is inconsistent in this regard)
	 *	- Adds "isMajor10" property to LineStrings and MultiLineStrings (typically elevation/contour data)
	 */
	get = async <T extends FeatureCollection = FeatureCollection>(
		id: string,
		cfg?: AllowedFetchOptions
	) => {
		const result = await this.geojson.get<T>(`/${id}.geojson.gz`, cfg);
		for (const feature of result.features) {
			if (feature.properties) {
				if (feature.properties.label && !feature.properties.legend) {
					feature.properties.legend = feature.properties.label;
				}
				if (feature.properties.legend && !feature.properties.label) {
					feature.properties.label = feature.properties.legend;
				}
				if (
					feature.geometry.type === 'LineString' ||
					feature.geometry.type === 'MultiLineString'
				) {
					feature.properties.isMajor10 = feature.properties.elev % 10 === 0;
				}
			}
			// PREVIOUS_TODO: we can remove this in release of ol 9.0.0 currently we are on "ol": "8.2.0",
			// cannot remove this as it will possibly break area calculation in some parts of the app
			if (feature.geometry.type === 'MultiPolygon') {
				if (feature.geometry.coordinates.length === 0) {
					feature.geometry.coordinates = [[[[]]]];
				}
			}
			if (feature.geometry.type === 'Polygon') {
				if (feature.geometry.coordinates.length === 0) {
					feature.geometry.coordinates = [[[]]];
				}
			}
		}
		return result;
	};
}
