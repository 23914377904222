import type { Client } from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class ClientsApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	list = async () => {
		return this.http.get<Array<Client>>('/client');
	};
}
