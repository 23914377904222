import { useApi } from '../api-query.context';
import { useQuery } from '@tanstack/react-query';
export function useIrricheckInfo(irricheck_id, opts) {
    const api = useApi();
    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps -- opts are expanded in the queryKey
        queryKey: ['irricheck-info', irricheck_id, opts.to, opts.from],
        queryFn: () => api.irrigation.irricheck(irricheck_id, opts),
        enabled: !!irricheck_id && opts.to > opts.from
    });
}
