import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import type { ITokenStorage } from '../../token-storage';
import { BaseApiModule } from '../base.api-module';

export type AppManagerApiModuleOptions = {
	domain: string;
	language: string;
	limiter: Limiter;
	tokenStorage: ITokenStorage;
	authErrorHandler: AuthErrorHandler;
	appName?: string;
};

export class AppManagerModule extends BaseApiModule {
	base: string;
	tokenStorage: ITokenStorage;
	appName?: string;
	constructor({
		domain,
		language,
		limiter,
		tokenStorage,
		appName,
		authErrorHandler
	}: AppManagerApiModuleOptions) {
		const base = `${domain}/${language}/api/5.0.0/app-manager/api`;
		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
		this.tokenStorage = tokenStorage;
		this.appName = appName;
	}

	async setToken(_token: string | null) {
		return this.http.auth(null);
	}

	async getPresignedURL({
		farmId,
		fileName
	}: {
		farmId: number;
		fileName: string;
	}) {
		const response = await this.http.post<{
			fields: {
				key: string;
				policy: string;
				signature: string;
			};
			url: string;
		}>(`/farm-reports/presigned-url/${farmId}/${fileName}`, null);
		return response;
	}

	firebaseCredentials = () => this.http.get<FirebaseCredentials>('/user');
}

export type FirebaseCredentials = {
	FIREBASE_TOKEN: string;
	USER_ID: string;
	mobile_clients: number[];
};
