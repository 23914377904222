import { compareMFWDates, isObject } from '@agritechnovation/utils';
import {
	type NewBasicAvailableLayer,
	type AvailableLayerObservation,
	type AvailableLayerObservationWithFarmId,
	type BasicAvailableLayer
} from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';
import { HTTPClient } from '../../http-client';
import type { TMSApiModule } from '../static/tms-static.api-module';
import { isNoDataValue } from '../../helpers';
import type { WellKnownName } from 'geostyler-style';
import type { AllowedFetchOptions } from '../base.api-module';

export class AvailableLayersApiModule extends BaseAgriApiModule {
	tms: TMSApiModule;
	constructor(opts: AgriModuleOptions & { tms: TMSApiModule }) {
		super(opts);
		this.tms = opts.tms;
	}

	combineByFarmAndDateWithObservations = async (
		farmId: number,
		filtergroupdid: number,
		al: BasicAvailableLayer | NewBasicAvailableLayer
	) => {
		const observations = await this.byFarmAndLayerFilterId(
			farmId,
			al.layer_filter_id
		);
		return {
			...al,
			farm_id: farmId,
			filterGroupId: filtergroupdid,
			observations,
			dates: al.dates.sort((a, b) =>
				compareMFWDates(isObject(a) ? a.date : a, isObject(b) ? b.date : b)
			)
		};
	};

	byFarmAndDate = async (
		id: number,
		opts: {
			from?: string;
			to?: string;
			search?: string;
			date_bucket?: string;
			filter_group_id?: number;
		},
		cfg?: AllowedFetchOptions
	) => {
		const params = new URLSearchParams();
		for (const [key, value] of Object.entries(opts)) {
			if (typeof value !== 'undefined') {
				params.append(key, String(value));
			}
		}
		const data = await this.http.get<
			Array<BasicAvailableLayer> | Array<NewBasicAvailableLayer>
		>(`/farms/${id}/layer-filters?${params.toString()}`, cfg);

		const batches: Array<
			Array<BasicAvailableLayer> | Array<NewBasicAvailableLayer>
		> = [];

		const BATCH_SIZE = 10;
		for (const [i, layer] of data.entries()) {
			const batchIndex = Math.floor(i / BATCH_SIZE);
			if (!batches[batchIndex]) {
				batches[batchIndex] = [];
			}
			(batches[batchIndex] as Array<BasicAvailableLayer>).push(
				layer as BasicAvailableLayer
			);
		}

		if (opts.filter_group_id === 112) {
			type CombineFn = (typeof this)['combineByFarmAndDateWithObservations'];
			type CombineResult = Awaited<ReturnType<CombineFn>>;

			const results: CombineResult[] = [];
			for (const batch of batches) {
				const obs = await Promise.all(
					batch.map((l) => {
						return this.combineByFarmAndDateWithObservations(
							id,
							opts.filter_group_id as number,
							l
						);
					})
				);
				for (const o of obs) {
					results.push(o as CombineResult);
				}
			}

			return results;
		}

		return data.map((x) => ({
			...x,
			farm_id: id,
			filterGroupId: opts.filter_group_id as number,
			dates: x.dates.sort((a, b) =>
				compareMFWDates(isObject(a) ? a.date : a, isObject(b) ? b.date : b)
			),
			observations: [] as AvailableLayerObservation[]
		}));
	};

	byFarmAndLayerFilterId = async (farmId: number, layerFilterId: number) => {
		const data = await this.http.get<AvailableLayerObservation[]>(
			`/layer-filters/layers/${farmId}/${layerFilterId}`
		);
		const withFarmId = data.map((x: AvailableLayerObservation) => ({
			...x,
			farm_id: farmId,
			layer_filter_id: layerFilterId
		}));
		return withFarmId as AvailableLayerObservationWithFarmId[];
	};

	style = async (farmId: number, filterId: number) => {
		try {
			const result = await this.http.get<RawLegend>(
				`/styles/${farmId}/${filterId}`
			);
			for (const key in result) {
				const colour = result[key].colour;
				const label = result[key].legend;
				if (colour === '0x000000' && isNoDataValue(label)) {
					delete result[key];
				}
				if (colour && result[key]?.colour) {
					result[key].colour = colour.replace('0x', '#');
				}
			}
			return result;
		} catch (error) {
			if (HTTPClient.isHTTPError(error) && error.status === 404) {
				return null;
			}
			throw error;
		}
	};

	cogStyle = async (farmId: number, filterId: number, verdeType: string) => {
		try {
			const result = await this.http.get<RawLegend>(
				`/styles/${farmId}/${filterId}`
			);
			for (const key in result) {
				const colour = result[key].colour;
				if (colour) {
					result[key].colour = colour.replace('0x', '#');
				}
			}
			return result;
		} catch (error) {
			if (HTTPClient.isHTTPError(error) && error.status === 404) {
				if (verdeType === 'fnpv') {
					verdeType = 'FNPV';
				}
				if (verdeType === 'fsoil') {
					verdeType = 'FSoil';
				}
				if (verdeType === 'fieldimage') {
					return null;
				}
				const response = await this.tms.get<{
					data: Record<
						string,
						{
							label: string | number;
							colour: string;
							legend: string;
							min: number;
						}
					>;
					total: number;
				}>(`/${verdeType}.json`);
				for (const [key, value] of Object.entries(response.data)) {
					response.data[key] = {
						...value,
						colour: key,
						label: parseFloat(value.label as string),
						legend: value.label as string,
						min: parseFloat(value.label as string)
					};
				}
				return response.data;
			}
			throw error;
		}
	};

	listAllLayerFilters = async (farmId: number) => {
		const result = await this.http.get<Array<{ layer_filter_id: number }>>(
			`/available-layers/layer-filter/farm/${farmId}`
		);
		return result.map((x) => x.layer_filter_id);
	};
}

export type RawLegendEntry = {
	colour?: string;
	color?: string;
	legend: string | number;
	label: string | number;
	graphicFill?: WellKnownName;
	max?: number;
	min?: number;
	exactly?: string | number;
	area?: number;
	exactValue?: string;
	areaPercentage: number;
	legend_en?: string;
	legend_es?: string;
	legend_af?: string;
};

export type RawLegend = Record<string, RawLegendEntry>;
