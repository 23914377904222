import type { FeatureCollection, Point } from 'geojson';
import type { CogHistogram } from '../../api.types';
import type { GISApiOptions } from './gis-base.api-module';
import { BaseGISApiModule } from './gis-base.api-module';

export class GISApiModule extends BaseGISApiModule {
	constructor(opts: GISApiOptions) {
		super(opts);
	}

	histogram = async (availableLayerId: number, bucketActive: boolean) => {
		return this.http.get<CogHistogram>(`/histogram/${availableLayerId}`, {
			params: {
				bucket_active: bucketActive.toString()
			}
		});
	};

	centroids = async (availableLayerId: number) => {
		const result = await this.http.get<
			Array<{
				id: number;
				name: string;
				latitude: number;
				longitude: number;
			}>
		>(`/centroid/available_layer/${availableLayerId}`);
		const asFC: FeatureCollection<Point> = {
			type: 'FeatureCollection',
			features: result.map((r) => ({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [r.longitude, r.latitude]
				},
				properties: {
					id: r.id,
					name: r.name,
					value: r.name
				}
			}))
		};
		return asFC;
	};
}
