import type { Feature, MultiPolygon, Polygon } from 'geojson';
import type { FeatureCollection } from 'geojson';
import type { AllowedFetchOptions } from '../base.api-module';
import { BaseApiModule } from '../base.api-module';
import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import { addAreaProperties } from '../../helpers/add-area-properties';
import type { Limiter } from '../../http-client/limiter';
import type { ITokenStorage } from '../../token-storage';

export class BoundaryApiModule extends BaseApiModule {
	base: string;
	constructor({
		domain,
		tokenStorage,
		limiter,
		authErrorHandler
	}: {
		domain: string;
		tokenStorage: ITokenStorage;
		limiter: Limiter;
		authErrorHandler: AuthErrorHandler;
	}) {
		const domainHost = new URL(domain).host;

		let base = domainHost.startsWith('us.')
			? 'https://us-boundary.agritechnovation.com/boundary/api'
			: 'https://boundary.agritechnovation.com/boundary/api';

		if (domainHost.startsWith('dev.')) {
			base = base.replace(
				'boundary.agritechnovation.com',
				'boundary-dev.agritechnovation.com'
			);
		}

		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'boundaryToken'
		});
		this.base = base;
	}

	farm = async (id: number, cfg?: AllowedFetchOptions) => {
		return addAreaProperties(
			await this.http.get<FeatureCollection<Polygon | MultiPolygon>>(
				`/geojson/boundary/${id}`,
				cfg
			)
		);
	};

	info = <T = unknown>(id: number, type: 'block' | 'sub-block') => {
		return this.http.get<
			Feature<
				Polygon | MultiPolygon,
				{
					name: string;
				} & T
			>
		>(`/boundary/${type}/${id}`);
	};

	byradius = async (
		opts: { lat: number; lon: number; radius: number },
		{ abort }: { abort?: AbortController | AbortSignal }
	) => {
		return this.http.get<number[]>('/farm/by-radius', {
			abort,
			params: {
				latitude: opts.lat,
				longitude: opts.lon,
				radius: opts.radius
			}
		});
	};

	blocks = async (farmId: number, cfg?: AllowedFetchOptions) => {
		return addAreaProperties(
			await this.http.get<FeatureCollection<Polygon | MultiPolygon>>(
				`/geojson/block/${farmId}`,
				cfg
			)
		);
	};

	subBlocks = async (blockId: number) => {
		return addAreaProperties(
			await this.http.get<FeatureCollection<Polygon | MultiPolygon>>(
				`/geojson/sub-block/${blockId}`
			)
		);
	};

	checkSubBlock = async (subBlockId: number) => {
		return this.http.get(`/check/sub-block/overlaps?ids=${subBlockId}`);
	};

	ppmBlocks = async (
		farmId: number,
		opts:
			| {
					date: string;
			  }
			| {
					date_bucket: string;
					crop_id?: number;
					observation_method_id?: number;
			  }
	) => {
		return addAreaProperties(
			await this.http.get<FeatureCollection<Polygon | MultiPolygon>>(
				`/geojson/ppm-boundary/${farmId}`,
				{
					params: opts
				}
			)
		);
	};

	ppm = async (id: number, date?: string) =>
		addAreaProperties(
			await this.http.get<FeatureCollection<Polygon | MultiPolygon>>(
				`/geojson/ppm/${id}${date ? `?date=${date}` : ''}`
			)
		);

	smartLayer = async (farmId: number) => {
		return this.http.get<FeatureCollection<MultiPolygon | Polygon>>(
			`/geojson/sub-block/smart-layer/${farmId}`
		);
	};
}
