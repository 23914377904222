import type { FeatureCollection, Point } from 'geojson';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';
import { isObject, roundNumber } from '@agritechnovation/utils';
import type { AllowedFetchOptions } from '../base.api-module';

export type PointDataGetOptions = {
	projectName: string;
	layerFilter: string;
	id: number;
	dateFrom: string;
	dateTo: string;
	itest_soil_depth_value?: string;
	cfg?: AllowedFetchOptions;
};
export class PointDataApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}
	get = async ({
		projectName,
		layerFilter,
		id,
		dateFrom,
		dateTo,
		itest_soil_depth_value,
		cfg
	}: PointDataGetOptions) => {
		const data = await this.http.get<FeatureCollection<Point>>('/point-data', {
			params: {
				project_name: projectName,
				layer_filter_name: layerFilter,
				show_pdf: 'True',
				using_color: 'true',
				from: dateFrom,
				to: dateTo,
				id: String(id),
				itest_soil_depth_value
			},
			...cfg
		});
		// flatten object properties
		for (const feature of data.features) {
			if (!feature.properties) continue;
			const keys = Object.keys(feature.properties);
			for (const key of keys) {
				const value =
					feature.properties[key as keyof typeof feature.properties];
				if (isObject(value)) {
					for (const valueKey in value) {
						feature.properties[`${key}_${valueKey}`] = value[valueKey];
						// make sure point properties are rounded to 2 decimal places, for display
						if (valueKey === 'value') {
							const val = value[valueKey];
							if (typeof val === 'number') {
								const rouneded = roundNumber(val, 2);
								feature.properties[`${key}_value`] = rouneded;
							}
						}
					}
					//https://github.com/geostyler/geostyler-openlayers-parser/issues/791
					feature.properties[`${key}_max_key`] = `${key}_max`;
					feature.properties[`${key}_min_key`] = `${key}_min`;
				}
				if (
					key === 'color_top' ||
					key === 'color_bottom' ||
					key === 'color_middle'
				) {
					feature.properties[key] = value.toLowerCase();
				}
			}
			if (layerFilter === 'my_soil_classification_probes') {
				feature.properties.x = feature.geometry.coordinates[0].toFixed(6);
				feature.properties.y = feature.geometry.coordinates[1].toFixed(6);
			}
		}
		return data;
	};
}
