import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import type { ITokenStorage } from '../../token-storage';
import { BaseApiModule } from '../base.api-module';

export type AgriModuleOptions = {
	domain: string;
	language: string;
	limiter: Limiter;
	tokenStorage: ITokenStorage;
	authErrorHandler: AuthErrorHandler;
	appName?: string;
};

export abstract class BaseAgriApiModule extends BaseApiModule {
	base: string;
	tokenStorage: ITokenStorage;
	appName?: string;
	protected constructor({
		domain,
		language,
		limiter,
		tokenStorage,
		appName,
		authErrorHandler
	}: AgriModuleOptions) {
		const base = `${domain}/${language}/api/5.0.0/agri/api`;
		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
		this.tokenStorage = tokenStorage;
		this.appName = appName;
	}
}
