import type { AuthErrorHandler } from '../../http-client';
import { HTTPClient } from '../../http-client';
import type { Limiter } from '../../http-client/limiter';
import type { ITokenStorage } from '../../token-storage';
import { BaseApiModule } from '../base.api-module';

export type BookmarkApiOptions = {
	domain: string;
	language: string;
	limiter?: Limiter;
	tokenStorage: ITokenStorage;
	authErrorHandler: AuthErrorHandler;
};

export class BookmarksApiModule extends BaseApiModule {
	base: string;
	constructor({
		domain,
		language,
		tokenStorage,
		limiter,
		authErrorHandler
	}: BookmarkApiOptions) {
		const base = `${domain}/${language}/api/5.0.0/agri/v2/api/sharable-bookmark`;
		const http = new HTTPClient(base, {
			limiter,
			authErrorHandler
		});
		super({
			http,
			tokenStorage,
			tokenKey: 'token'
		});
		this.base = base;
	}
	list = () => {
		return this.http.get<ShareableBookmark[]>('');
	};
	get = (id: number) => {
		return this.http.get<ShareableBookmark>(`/${id}`);
	};
	create = (opts: CreateShareableBookmark) => {
		return this.http.post<ShareableBookmark>('', opts);
	};
	addFarms = (payload: UpdateBookmarkFarms) => {
		return this.http.put<void>(`/${payload.id}/farms`, payload);
	};
	addLayerFilters = (payload: UpdateBookmarkLayerFilters) => {
		return this.http.put<void>(`/${payload.id}/layer-filters`, payload);
	};
	removeFarms = (payload: DeleteBookmarkFarms) => {
		return this.http.delete<void>(`/${payload.id}/farms`, {
			fetch: {
				body: JSON.stringify(payload)
			}
		});
	};
	removeLayerFilters = (payload: DeleteBookmarkLayerFilters) => {
		return this.http.delete<void>(`/${payload.id}/layer-filters`, {
			fetch: { body: JSON.stringify(payload) }
		});
	};
	delete = (id: number) => {
		return this.http.delete<void>(`/${id}`);
	};
	share = (payload: ShareBookmark) => {
		return this.http.put<void>(`/${payload.id}/share`, payload);
	};
	/**
	 * If the user is NOT the owner of the bookmark, they can remove the bookmark from their list of shared bookmarks by submitting only their email address with the payload.
	 */
	unshare = (payload: ShareBookmark) => {
		return this.http.delete<void>(`/${payload.id}/share`, {
			fetch: { body: JSON.stringify(payload) }
		});
	};
}
export interface ShareableBookmarks {
	/**
	 * List the bookmarks linked to a user
	 * */
	list(): Promise<ShareableBookmark[]>;
	get(id: ShareableBookmark['id']): Promise<ShareableBookmark>;
	create(payload: CreateShareableBookmark): Promise<ShareableBookmark>;

	addLayerFilters(payload: UpdateBookmarkLayerFilters): Promise<void>;

	removeLayerFilters(payload: DeleteBookmarkLayerFilters): Promise<void>;

	addFarms(payload: UpdateBookmarkFarms): Promise<void>;

	removeFarms(payload: DeleteBookmarkFarms): Promise<void>;

	delete(id: ShareableBookmark['id']): Promise<void>;

	share(payload: ShareBookmark): Promise<void>;

	unshare(payload: ShareBookmark): Promise<void>;
}

export interface ShareableBookmarks {
	/**
	 * List the bookmarks linked to a user
	 * */
	list(): Promise<ShareableBookmark[]>;
	get(id: ShareableBookmark['id']): Promise<ShareableBookmark>;
	create(payload: CreateShareableBookmark): Promise<ShareableBookmark>;

	addLayerFilters(payload: UpdateBookmarkLayerFilters): Promise<void>;

	removeLayerFilters(payload: DeleteBookmarkLayerFilters): Promise<void>;

	addFarms(payload: UpdateBookmarkFarms): Promise<void>;

	removeFarms(payload: DeleteBookmarkFarms): Promise<void>;

	delete(id: ShareableBookmark['id']): Promise<void>;

	share(payload: ShareBookmark): Promise<void>;

	unshare(payload: ShareBookmark): Promise<void>;
}

export type ShareableBookmark = {
	id: number;
	name: string;
	farms: number[];
	layer_filters: number[];
	/**
	 * User ID
	 */
	owner: number;
	shared_with: { email: string }[];
	is_owner: boolean;
};

export type CreateShareableBookmark = {
	name: string;
	farm_ids: number[];
	layer_filter_ids: number[];
};

export type UpdateBookmarkFarms = {
	id: number;
	farm_ids: number[];
};

export type DeleteBookmarkFarms = UpdateBookmarkFarms;

export type UpdateBookmarkLayerFilters = {
	id: number;
	layer_filter_ids: number[];
};

export type ShareBookmark = {
	id: number;
	emails: string[];
};

export type UnShareBookmark = ShareBookmark;

export type DeleteBookmarkLayerFilters = UpdateBookmarkLayerFilters;
