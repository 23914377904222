import { isObject, Milliseconds } from '@agritechnovation/utils';
import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useApi } from '../api-query.context';
import { useArchivedLayerFiltersForManyFarms } from './use-archived-layer-filters';
import { createLayerFilterQuery } from './use-layer-filter';
export function farmAvailableLayersQueryOptions(api, id, options) {
    return queryOptions({
        queryKey: [
            'available-layers',
            id,
            options.dateFilter?.dateFilter.interval,
            options.dateFilter?.from,
            options.dateFilter?.to,
            options.filterGroup
        ],
        queryFn: ({ signal }) => api.availableLayers.byFarmAndDate(id, {
            from: options.dateFilter?.from,
            to: options.dateFilter?.to,
            filter_group_id: options.filterGroup
        }, {
            abort: signal
        })
    });
}
export function useFarmsAvailableLayers(farms, { dateFilter, filters: filterGroups }) {
    const api = useApi();
    const { data: archivedLayerFilters, isPending: archivedPending } = useArchivedLayerFiltersForManyFarms(farms);
    return useQueries({
        queries: farms
            .map((farm) => filterGroups.map((filter) => queryOptions({
            queryKey: [
                'available-layers',
                farm,
                dateFilter.dateFilter.interval,
                dateFilter.from,
                dateFilter.to,
                filter
            ],
            queryFn: async ({ signal }) => {
                const result = await api.availableLayers.byFarmAndDate(farm, {
                    from: dateFilter.from,
                    to: dateFilter.to,
                    filter_group_id: filter
                }, { abort: signal });
                return result;
            },
            select: (data) => {
                return data.filter((al) => {
                    const archived = archivedLayerFilters.find((f) => f.layer_filter_id === al.layer_filter_id &&
                        f.farm_id === farm);
                    return !archived;
                });
            },
            enabled: !!filterGroups && filterGroups.length > 0 && !archivedPending,
            refetchInterval: Milliseconds.minutes(10)
        })))
            .flat(),
        combine: (results) => {
            const data = [];
            for (const result of results) {
                if (!result.data) {
                    continue;
                }
                for (const datapoint of result.data) {
                    const l = data.find((l) => l.layerFilterId === datapoint.layer_filter_id);
                    if (l) {
                        l.availableLayers.push(
                        // TODO: Fix this type, probably will discard when endpoint changes have been made
                        datapoint);
                    }
                    else {
                        // TODO Fix this type, probably will discard when endpoint changes have been made
                        data.push({
                            layerFilterId: datapoint.layer_filter_id,
                            filterGroupId: datapoint.filterGroupId,
                            availableLayers: [datapoint]
                        });
                    }
                }
            }
            const isPending = results.some((result) => result.isPending);
            const isError = results.some((result) => result.isError);
            const isFetching = results.some((result) => result.isRefetching);
            const retry = async function ({ throwOnError = false } = {}) {
                await Promise.all(results.map((result) => result.refetch({ throwOnError })));
            };
            return {
                data,
                isPending,
                isFetching,
                isError,
                retry
            };
        }
    });
}
export function getAllLayersInFilterGroup(filterGroup) {
    const layers = [...getLayersInFilterGroup(filterGroup)];
    function getLayersInFilterGroup(filterGroup) {
        if (filterGroup.layer_filters)
            return filterGroup.layer_filters;
        return [];
    }
    if (filterGroup.filter_groups) {
        for (const subGroup of filterGroup.filter_groups) {
            layers.push(...getAllLayersInFilterGroup(subGroup));
        }
    }
    return layers;
}
export function useAvailableLayers(farms, categories, filterGroupIds, dateFilter) {
    const api = useApi();
    const { data, isPending: farmDataPending, isFetching: farmDataFetching, isError, retry } = useFarmsAvailableLayers(farms, {
        dateFilter: {
            dateFilter: dateFilter.dateFilter,
            from: dateFilter.from,
            to: dateFilter.to
        },
        filters: filterGroupIds
    });
    const layerFilterResults = useQueries({
        queries: data.map(({ layerFilterId }) => createLayerFilterQuery(layerFilterId, { api, enabled: true })),
        combine: (result) => {
            return {
                available: result.some((r) => r.data),
                data: result.filter((r) => r.data).map((r) => r.data),
                isPending: result.some((r) => r.isPending),
                isError: result.some((r) => r.isError)
            };
        }
    });
    const merged = useMemo(() => {
        if (!data)
            return [];
        const allowedLayerFilters = layerFilterResults.data.filter((l) => {
            const inCategory = categories.includes(l.layer_type);
            return inCategory;
        });
        const merged = [];
        for (const d of data) {
            const layerFilter = allowedLayerFilters.find((l) => l.id === d.layerFilterId);
            if (layerFilter) {
                merged.push({
                    ...d,
                    layerFilter
                });
            }
        }
        return merged;
    }, [data, layerFilterResults.data, categories]);
    const isPending = farmDataPending || layerFilterResults.isPending;
    const isAvailable = layerFilterResults.available;
    return [
        merged,
        layerFilterResults.data,
        isPending,
        isAvailable,
        isError || layerFilterResults.isError,
        retry,
        farmDataFetching
    ];
}
export function allAvailableLayerFilterIds(farmId, options) {
    return queryOptions({
        queryKey: ['all-available-layer-filter-ids', farmId],
        queryFn: () => options.api.availableLayers.listAllLayerFilters(farmId),
        enabled: !!farmId
    });
}
export function useAllAvailableLayersLayerFilterIds(farmId) {
    const api = useApi();
    return useQuery(allAvailableLayerFilterIds(farmId, { api }));
}
export function makeITESTSoilDepthKey(depth, unit) {
    if (!unit) {
        return depth;
    }
    return `${depth}||${unit}`;
}
export function readITESTSoilDepthKey(key) {
    if (key === null) {
        return { depth: '', unit: null };
    }
    const [depth, unit] = key.split('||');
    return { depth, unit: unit ?? null };
}
export function displayITESTSoilDepth(key) {
    const { depth, unit } = readITESTSoilDepthKey(key);
    if (depth && unit) {
        return `${depth} ${unit}`;
    }
    if (depth)
        return depth;
    return '';
}
export function groupAvailableLayersByDepth(results) {
    const groupedByDepth = new Map();
    // layerFilterId is key of this map
    const withDepthRemoved = new Map();
    for (const result of results) {
        if (result.filterGroupId !== 112) {
            continue;
        }
        for (const al of result.availableLayers) {
            const withDepth = al.observations
                .map((x) => makeITESTSoilDepthKey(x.itest_soil_depth_value, x.itest_soil_depth_value_unit))
                .filter(Boolean);
            if (withDepth.length > 0) {
                for (const depth of withDepth) {
                    const ofDepth = al.observations.filter((x) => makeITESTSoilDepthKey(x.itest_soil_depth_value, x.itest_soil_depth_value_unit) === depth);
                    const ofDepthDates = ofDepth.map((x) => x.date);
                    const ofDepthDateBuckets = ofDepth.map((x) => x.date_bucket);
                    const filteredAl = {
                        ...al,
                        dates: al.dates.filter((x) => isObject(x)
                            ? ofDepthDates.includes(x.date)
                            : ofDepthDates.includes(x)),
                        date_buckets: al.date_buckets.filter((x) => isObject(x)
                            ? ofDepthDateBuckets.includes(x.date_bucket)
                            : ofDepthDateBuckets.includes(x)),
                        observations: ofDepth
                    };
                    const exists = groupedByDepth.has(depth);
                    if (exists) {
                        const existing = groupedByDepth.get(depth);
                        if (existing) {
                            const existingLayer = existing.find((x) => x.layerFilterId === al.layer_filter_id);
                            if (existingLayer) {
                                const updated = {
                                    ...existingLayer,
                                    availableLayers: [
                                        ...existingLayer.availableLayers,
                                        filteredAl
                                    ]
                                };
                                groupedByDepth.set(depth, [
                                    // @ts-expect-error -- tight aussie deadline
                                    ...existing.filter((l) => l.layerFilterId !== updated.layerFilterId),
                                    // @ts-expect-error -- tight aussie deadline
                                    updated
                                ]);
                            }
                            else {
                                const updated = {
                                    ...result,
                                    availableLayers: [filteredAl]
                                };
                                groupedByDepth.set(depth, [
                                    // @ts-expect-error -- tight aussie deadline
                                    ...existing.filter((l) => l.layerFilterId !== updated.layerFilterId),
                                    // @ts-expect-error -- tight aussie deadline
                                    updated
                                ]);
                            }
                        }
                    }
                    else {
                        const updated = {
                            ...result,
                            availableLayers: [filteredAl]
                        };
                        // @ts-expect-error -- tight aussie deadline
                        groupedByDepth.set(depth, [updated]);
                    }
                }
            }
            const withoutDepth = al.observations.filter((f) => f.itest_soil_depth_value === null);
            const withoutDepthDates = withoutDepth.map((x) => x.date);
            const withoutDepthDateBuckets = withoutDepth.map((x) => x.date_bucket);
            if (withoutDepth.length > 0) {
                const filteredAl = {
                    ...al,
                    dates: al.dates.filter((x) => isObject(x)
                        ? withoutDepthDates.includes(x.date)
                        : withoutDepthDates.includes(x)),
                    date_buckets: al.date_buckets.filter((x) => isObject(x)
                        ? withoutDepthDateBuckets.includes(x.date_bucket)
                        : withoutDepthDateBuckets.includes(x))
                };
                if (filteredAl.dates.length === 0 &&
                    filteredAl.date_buckets.length === 0) {
                    continue;
                }
                const existing = withDepthRemoved.get(al.layer_filter_id);
                if (existing) {
                    const updated = {
                        ...existing,
                        availableLayers: [...existing.availableLayers, filteredAl]
                    };
                    withDepthRemoved.set(al.layer_filter_id, 
                    // @ts-expect-error -- tight aussie deadline
                    updated);
                }
                else {
                    const updated = {
                        ...result,
                        availableLayers: [filteredAl]
                    };
                    // @ts-expect-error -- tight aussie deadline
                    withDepthRemoved.set(al.layer_filter_id, updated);
                }
            }
        }
    }
    return {
        byDepth: Array.from(groupedByDepth.entries()).sort(([a], [b]) => parseInt(a) - parseInt(b)),
        withDepthRemoved: Array.from(withDepthRemoved.values()).flat()
    };
}
export function useGroupedAvailableLayersByDepth(results) {
    return useMemo(() => groupAvailableLayersByDepth(results), [results]);
}
