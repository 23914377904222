import { useApi } from '../api-query.context';
import { useQueries, useQuery, keepPreviousData, queryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Milliseconds, parseYYYYMMDD, toYYYYMMDD } from '@agritechnovation/utils';
import { eachDayOfInterval } from 'date-fns';
function handleRefetchInterval(args) {
    const date = args.queryKey.at(-1);
    const today = toYYYYMMDD(new Date());
    const isToday = date === today;
    return isToday ? Milliseconds.seconds(10) : false;
}
export function createVehicleHistoryQuery(api, { farmId, vehicleId, date }) {
    return queryOptions({
        queryKey: ['farm', farmId, 'my-assets', 'vehicle-history', vehicleId, date],
        queryFn: () => api.assets.history(vehicleId, date),
        placeholderData: keepPreviousData,
        refetchInterval: handleRefetchInterval
    });
}
export function useVehicleHistory({ farmId, vehicleId, date }) {
    const api = useApi();
    return useQuery(createVehicleHistoryQuery(api, { farmId, vehicleId, date }));
}
export function useVehicleHistoryRange(options) {
    const api = useApi();
    const queries = useMemo(() => {
        const queries = [];
        const interval = {
            start: parseYYYYMMDD(options.dateRange[0]),
            end: parseYYYYMMDD(options.dateRange[1])
        };
        const today = toYYYYMMDD(new Date());
        for (const date of eachDayOfInterval(interval)) {
            const yyyyMMdd = toYYYYMMDD(date);
            queries.push({
                ...createVehicleHistoryQuery(api, {
                    ...options,
                    date: yyyyMMdd
                }),
                refetchInterval: yyyyMMdd === today ? Milliseconds.seconds(20) : undefined
            });
        }
        return queries;
    }, [api, options]);
    return useQueries({
        queries,
        combine: (results) => {
            const isPending = results.some((r) => r.isPending);
            const features = [];
            for (const query of results) {
                if (query.data) {
                    features.push(...query.data.features);
                }
            }
            features.sort((a, b) => a.properties.history_time.localeCompare(b.properties.history_time));
            return {
                isPending,
                data: {
                    type: 'FeatureCollection',
                    features
                }
            };
        }
    });
}
