import { useApi } from '../api-query.context';
import { useQuery } from '@tanstack/react-query';
export function useFarmAssets(farmId, filter) {
    const api = useApi();
    return useQuery({
        queryKey: ['assets', farmId],
        queryFn: () => api.assets.list(farmId),
        enabled: !!farmId,
        select: (assets) => {
            if (filter) {
                return assets.filter(filter);
            }
            return assets;
        }
    });
}
export function useVehicleDetails(vehicleId) {
    const api = useApi();
    return useQuery({
        queryKey: ['vehicle', vehicleId],
        queryFn: () => api.assets.get(vehicleId)
    });
}
