import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function ITESTMicroLifeDatesQuery(api, farmId) {
    return queryOptions({
        queryKey: ['itest-micro-life', 'dates', farmId],
        queryFn: () => api.microLife.reportDates(farmId)
    });
}
export function useITESTMicroLifeDates(farmId) {
    const api = useApi();
    return useQuery(ITESTMicroLifeDatesQuery(api, farmId));
}
export function useITESTMicroLifeReports(options) {
    const api = useApi();
    return useQuery({
        queryKey: [
            'itest-micro-life',
            'reports',
            options.farmId,
            options.from,
            options.to
        ],
        queryFn: () => {
            if (!options.from || !options.to)
                throw new Error('from and to are required');
            return api.microLife.reports({
                farmId: options.farmId,
                from: options.from,
                to: options.to
            });
        },
        enabled: !!options.from && !!options.to
    });
}
