import { queryOptions, useQuery } from '@tanstack/react-query';
import { useUser } from './use-user';
import { useApi } from '../api-query.context';
import { getQueryResult } from '../utils/query';
import { useMemo } from 'react';
export function createLayerFilterQuery(layerId, { api, enabled = true }) {
    return queryOptions({
        queryKey: ['layer-filter', layerId],
        queryFn: ({ signal }) => {
            if (!layerId)
                throw new Error('No layer filter id');
            return api.layers.get(layerId, signal);
        },
        enabled: !!layerId && enabled,
        staleTime: 24 * 60 * 60 * 1000
    });
}
export function useLayerFilterOptions(layerId, { enabled = true }) {
    const api = useApi();
    return useMemo(() => createLayerFilterQuery(layerId, { api, enabled }), [layerId, api, enabled]);
}
export const useLayerFilter = (layerId) => {
    const { data: user } = useUser();
    const options = useLayerFilterOptions(layerId, { enabled: !!user });
    return useQuery(options);
};
export function getLayerFilterById(id, { api, queryClient }) {
    return getQueryResult(queryClient, ['layer-filter', id], () => api.layers.get(id));
}
