import type { Farm } from '../../api.types';
import type { AllowedFetchOptions } from '../base.api-module';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class FarmsApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	list = async () => {
		return this.http.get<Farm[]>('/farm');
	};

	get = async (id: number, cfg?: AllowedFetchOptions) => {
		return this.http.get<Farm>(`/farm/${id}`, cfg);
	};

	search = async (opts: {
		search?: string;
		perPage?: number;
		page?: number;
	}) => {
		return this.http.get<Farm[]>('/farms', {
			params: opts
		});
	};
}
