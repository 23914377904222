import type { RequestConfig, URLPath } from '../../http-client';
import type { MFWStaticHost } from './base-static.api-module';
import { BaseStaticApi } from './base-static.api-module';

export class TMSApiModule extends BaseStaticApi<MFWStaticHost> {
	constructor({ domain }: { domain: MFWStaticHost }) {
		super({
			domain
		});
	}

	get = async <T>(path: URLPath, mime?: RequestConfig['mime']) => {
		return this.http.get<T>(`/tms${path}`, {
			mime
		});
	};

	exists = async (path: URLPath) => {
		return this.http.head(`/tms${path}`);
	};

	createURL = (path: URLPath) => {
		return `${this.base}/tms${path}`;
	};
}
