export function createTokenStorage(create: CreateTokenStorageOptions) {
	return new TokenStorage(create);
}
export type TokenStorageListenerArgs = {
	[Key in Exclude<TokenKey, 'isRefreshing'>]: string | null;
};

export type TokenStorageListener = (args: TokenStorageListenerArgs) => void;

// we need username because the refresh endpoint requires it :(
export type TokenKey =
	| 'token'
	| 'boundaryToken'
	| 'refreshToken'
	| 'username'
	| 'cropServiceToken'
	| 'isRefreshing';

export type CreateTokenStorageOptions = {
	getToken: (key: TokenKey) => Promise<string | null>;
	setToken: (key: TokenKey, token: string | null) => Promise<void>;
};
export interface ITokenStorage extends TokenStorageListenerArgs {
	setToken: (token: string | null) => Promise<void>;
	// set boundary token always notifies because it should always be set after token and refresh token
	setBoundaryToken: (token: string | null) => Promise<void>;
	setRefreshToken: (token: string | null) => Promise<void>;
	setUsername: (username: string | null) => Promise<void>;
	setCropServiceToken: (token: string | null) => Promise<void>;
	getToken: () => Promise<string | null>;
	getUsername: () => Promise<string | null>;
	getBoundaryToken: () => Promise<string | null>;
	getRefreshToken: () => Promise<string | null>;
	all: () => Promise<TokenStorageListenerArgs>;
	listen: (cb: TokenStorageListener) => () => void;
	setIsRefreshing: (isRefreshing: boolean) => Promise<void>;
	isRefreshing: () => Promise<boolean>;
}

class TokenStorage implements ITokenStorage {
	token: string | null = null;
	boundaryToken: string | null = null;
	refreshToken: string | null = null;
	username: string | null = null;
	password: string | null = null;
	cropServiceToken: string | null = null;
	listeners: TokenStorageListener[] = [];
	constructor(private create: CreateTokenStorageOptions) {}

	setIsRefreshing = async (isRefreshing: boolean) => {
		await this.create.setToken('isRefreshing', isRefreshing ? 'true' : null);
	};

	isRefreshing = async () => {
		const val = await this.create.getToken('isRefreshing');
		return val === 'true';
	};

	listen = (cb: TokenStorageListener) => {
		this.listeners.push(cb);
		return () => {
			this.listeners = this.listeners.filter((l) => l !== cb);
		};
	};

	#notify = () => {
		this.listeners.forEach((l) =>
			l({
				token: this.token,
				boundaryToken: this.boundaryToken,
				refreshToken: this.refreshToken,
				username: this.username,
				cropServiceToken: this.cropServiceToken
			})
		);
	};

	all = async () => {
		this.token = await this.getToken();
		this.boundaryToken = await this.getBoundaryToken();
		this.refreshToken = await this.getRefreshToken();
		this.username = await this.getUsername();
		this.cropServiceToken = await this.create.getToken('cropServiceToken');
		return {
			token: this.token,
			boundaryToken: this.boundaryToken,
			refreshToken: this.refreshToken,
			username: this.username,
			cropServiceToken: this.cropServiceToken
		};
	};

	getToken = async () => {
		return await this.create.getToken('token');
	};

	getBoundaryToken = async () => {
		this.boundaryToken = await this.create.getToken('boundaryToken');
		return this.boundaryToken;
	};

	getRefreshToken = async () => {
		this.refreshToken = await this.create.getToken('refreshToken');
		return this.refreshToken;
	};

	setToken = async (token: string | null) => {
		this.token = token;
		await this.create.setToken('token', token);
		this.boundaryToken = null;
		this.#notify();
	};

	setBoundaryToken = async (token: string | null) => {
		this.boundaryToken = token;
		await this.create.setToken('boundaryToken', token);
		this.#notify();
	};

	setRefreshToken = async (token: string | null) => {
		this.refreshToken = token;
		await this.create.setToken('refreshToken', token);
		this.#notify();
	};

	setUsername = async (username: string | null) => {
		this.username = username;
		await this.create.setToken('username', username);
	};

	getUsername = async () => {
		this.username = await this.create.getToken('username');
		return this.username;
	};

	setCropServiceToken = async (token: string | null) => {
		this.cropServiceToken = token;
		await this.create.setToken('cropServiceToken', token);
	};
}
