import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export const useClientsQueryOptions = (api) => queryOptions({
    queryKey: ['clients'],
    queryFn: () => api.clients.list()
});
export function useClients() {
    const api = useApi();
    return useQuery({
        queryKey: ['clients'],
        queryFn: () => {
            return api.clients.list();
        }
    });
}
