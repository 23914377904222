import type { LayerFilter } from '../../api.types';

export function translatePPMLayerFilter(inputLayer: LayerFilter) {
	const layer = { ...inputLayer };
	const translations = {
		scouting: {
			en: 'Scouting',
			af: 'Verkenning',
			es: 'Exploración'
		},
		traps: {
			en: 'Traps',
			af: 'Valke',
			es: 'Trampas'
		}
	};
	const cropAndMethod = layer.full_name.replace(layer.name, '').trim();

	layer.name_en += ' ';
	layer.name_af += ' ';
	layer.name_es += ' ';

	layer.name_en += cropAndMethod
		.replace('Scouting', translations.scouting.en)
		.replace('Traps', translations.traps.en);

	layer.name_af += cropAndMethod
		.replace('Scouting', translations.scouting.af)
		.replace('Traps', translations.traps.af);

	layer.name_es += cropAndMethod
		.replace('Scouting', translations.scouting.es)
		.replace('Traps', translations.traps.es);

	return layer;
}

export function extractTranslatedCropAndMethodFromLayerFilter(
	layerFilter: LayerFilter
) {
	if (
		layerFilter.layer_type === 'point' ||
		layerFilter.layer_type === 'pdf_point'
	) {
		return {
			name: layerFilter.name,
			crop: '',
			method: ''
		};
	}
	const betweenBrackets = layerFilter.full_name
		.replace(layerFilter.name, '')
		.replace('(min)', '')
		.replace('(max)', '')
		.replace('(avg)', '')
		.replace('(sum)', '')
		.replace('(count)', '')
		.replace('(%)', '')
		.replace('(Nr.)', '')
		.replace('(Pest Count)', '')
		.match(/\(([^)]+)\)/);

	const errorMessage = `Layer filter full name,  "${layerFilter.full_name}", does not match expected format`;
	if (!betweenBrackets) {
		throw new Error(errorMessage);
	}
	if (betweenBrackets.length < 1) {
		throw new Error(errorMessage);
	}
	const [crop, method] = betweenBrackets[0].split(',');
	if (!crop || !method) {
		throw new Error(errorMessage);
	}
	return {
		name: layerFilter.name, // TODO: translate these keys
		crop: crop.trim().replace(')', '').replace('(', ''),
		method: method.trim().replace(')', '').replace('(', '')
	};
}
