import { queryOptions, useQuery } from '@tanstack/react-query';
import { useUser } from './use-user';
import { useApi } from '../api-query.context';
export function farmQueryOptions(api, id) {
    return queryOptions({
        queryKey: ['farm', id],
        queryFn: async ({ signal }) => {
            return await api.farms.get(id, { abort: signal });
        }
    });
}
export const useFarm = (id) => {
    const api = useApi();
    const { data: user } = useUser();
    return useQuery({
        ...farmQueryOptions(api, id),
        enabled: !!user && !!id
    });
};
