import { endOfDay, format, startOfDay } from 'date-fns';
import type { Vehicle, VehicleHistory } from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class AssetsApiModule extends BaseAgriApiModule {
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	list = async (farmId: number) => {
		const vehicles = await this.http.get<
			Array<{
				id: string;
				name: string;
				icon: string;
			}>
		>(`/vehicle/${farmId}`);
		return await Promise.all(vehicles.map((v) => this.get(v.id)));
	};

	get = (assetId: string) => {
		return this.http.get<Vehicle>(`/vehicle/${assetId}/detail`);
	};

	history = (assetId: string, date: string) => {
		const time_from = format(startOfDay(new Date(date)), 'HH:mm:ss');
		const time_to = format(endOfDay(new Date(date)), 'HH:mm:ss');
		const params = new URLSearchParams({
			vehicle_id: assetId,
			date,
			time_from,
			time_to
		});
		return this.http.get<VehicleHistory>(`/vehicle-history/history?${params}`);
	};
}
