import { useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export function useFeatureFiles(featureId, availableLayerId) {
    const api = useApi();
    return useQuery({
        queryKey: ['feature-files', featureId, availableLayerId],
        queryFn: () => api.files.get(featureId, availableLayerId),
        enabled: featureId !== undefined && availableLayerId !== undefined,
        retry: false,
        select: (data) => {
            return data.map((file) => {
                if (typeof file === 'string') {
                    if (URL.canParse(file)) {
                        const url = new URL(file);
                        return {
                            file,
                            title: decodeURIComponent(url.pathname.split('/').pop()),
                            uploader: '',
                            pk: featureId,
                            tags: '',
                            can_delete: false
                        };
                    }
                    return {
                        file,
                        title: file,
                        uploader: '',
                        pk: featureId,
                        tags: '',
                        can_delete: false
                    };
                }
                return file;
            });
        }
    });
}
