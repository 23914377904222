import type { FilterGroup, FilterGroupsForAppMenu } from '../../api.types';
import type { AllowedFetchOptions } from '../base.api-module';
import type { MediaStaticApiModule } from '../static';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export class FilterGroupsApiModule extends BaseAgriApiModule {
	media: MediaStaticApiModule;
	constructor(
		opts: AgriModuleOptions & {
			media: MediaStaticApiModule;
		}
	) {
		super(opts);
		this.media = opts.media;
	}

	list = async (v2?: boolean, forappmenu = true, cfg?: AllowedFetchOptions) => {
		if (v2) {
			return this.http.get<FilterGroupsForAppMenu[]>('/filter-groups', {
				params: {
					'for-app-menu': forappmenu
				},
				reBasePath: this.base.replace('/agri/api', '/agri/v2'),
				...cfg
			});
		}
		return this.http.get<FilterGroupsForAppMenu[]>('/filter-groups', {
			params: {
				'for-app-menu': forappmenu
			}
		});
	};

	get = async (id: number) =>
		this.http.get<FilterGroup>(`/filter-groups/${id}`, {
			reBasePath: this.base.replace('/agri/api', '/agri/v2')
		});

	layers = async (id: number) =>
		this.http.get<number[]>(`/filter-groups/${id}/layers`, {
			reBasePath: this.base.replace('/agri/api', '/agri/v2')
		});

	icon = async (path: string) => {
		const fileName = path.replace(/^.*[\\\/]/, '');
		return this.media.get<string>(`/group_icons/${fileName}`, {
			mime: 'text'
		});
	};
}
