import { add } from 'date-fns';
import type {
	WeatherForecastResponse,
	WeatherHistory,
	WeatherHistoryOpts,
	WeatherSprayForecastOptions
} from '../../api.types';
import type {
	WeatherCurrentOptions,
	WeatherCurrentResponse,
	WeatherCurrentReturnType,
	WeatherForecastOptions,
	WeatherProvider,
	WeatherSprayForecast,
	WeatherSprayForecastResponse,
	WeatherStation
} from '../../api.types';
import type { AgriModuleOptions } from './agri-base.api-module';
import { BaseAgriApiModule } from './agri-base.api-module';

export interface WeatherModule {
	stations(opts: { farmId: number }): Promise<Array<WeatherStation>>;

	current<T extends WeatherProvider>(
		opts: WeatherCurrentOptions<T>
	): Promise<WeatherCurrentReturnType<T>>;

	forecast<T extends 'openweathermap' | 'hortec_ileaf_data' | 'metos'>(
		opts: WeatherForecastOptions<T>
	): Promise<WeatherForecastResponse<T>>;

	sprayForecast<T extends 'hortec_ileaf_data' | 'metos'>(
		opts: WeatherSprayForecastOptions<T>
	): Promise<WeatherSprayForecast>;

	history<T extends Extract<WeatherProvider, 'hortec_ileaf_data'>>(
		opts: WeatherHistoryOpts<T>
	): Promise<WeatherHistory>;
}

export class WeatherApiModule
	extends BaseAgriApiModule
	implements WeatherModule
{
	constructor(opts: AgriModuleOptions) {
		super(opts);
	}

	current = async <T extends WeatherProvider>(
		opts: WeatherCurrentOptions<T>
	) => {
		let path = 'current';
		const searchParams = new URLSearchParams();
		searchParams.append('service', opts.service);
		if (opts.service === 'openweathermap') {
			searchParams.append('lat', String(opts.lat));
			searchParams.append('lon', String(opts.lng));
		}
		if (opts.service !== 'openweathermap') {
			searchParams.append('farm', String(opts.farmId));
		}
		if (opts.service === 'davis_weather') {
			searchParams.append('station', String(opts.stationId));
		}
		path += `?${searchParams.toString()}`;
		const result = await this.http.get(`/weather/${path}`);
		if (opts.service === 'metos') {
			const metosResult = (result as WeatherCurrentResponse<'metos'>).find(
				(r) => r.station_id === String(opts.stationId)
			) as WeatherCurrentResponse<'metos'>[number];
			return metosResult as WeatherCurrentReturnType<T>;
		}
		return result as WeatherCurrentReturnType<Exclude<T, 'metos'>>;
	};

	stations = async (opts: { farmId: number }) => {
		return this.http.get<Array<WeatherStation>>(
			`/weather/stations/${opts.farmId}`,
			{
				reBasePath: this.base.replace('/agri/api', '/agri/v2')
			}
		);
	};

	forecast = async <T extends 'openweathermap' | 'hortec_ileaf_data' | 'metos'>(
		opts: WeatherForecastOptions<T>
	) => {
		const searchParams = new URLSearchParams();
		searchParams.append('service', opts.service);
		if (opts.service === 'openweathermap') {
			searchParams.append('lat', String(opts.lat));
			searchParams.append('lon', String(opts.lng));
		} else {
			searchParams.append('farm', String(opts.farmId));
		}
		if (opts.service === 'hortec_ileaf_data') {
			searchParams.append('hortec_station', String(opts.stationId));
		}
		if (opts.service === 'metos') {
			searchParams.append('station_id', String(opts.stationId));
		}
		const path = `forecasting?${searchParams.toString()}`;
		return this.http.get<WeatherForecastResponse<T>>(`/weather/${path}`);
	};

	history = async <T extends Extract<WeatherProvider, 'hortec_ileaf_data'>>(
		opts: WeatherHistoryOpts<T>
	) => {
		const searchParams = new URLSearchParams();
		searchParams.append('service', opts.service);
		searchParams.append('hortec_station', String(opts.stationId));
		// unnecessary but required by the api, not used by the api
		searchParams.append('lat', String(0));
		searchParams.append('lon', String(0));
		const path = `history?${searchParams.toString()}`;
		return this.http.get<WeatherHistory>(`/weather/${path}`);
	};
	sprayForecast = async <T extends 'hortec_ileaf_data' | 'metos'>(
		opts: WeatherSprayForecastOptions<T>
	) => {
		const path =
			opts.service === 'hortec_ileaf_data'
				? 'hortec-forecast-spray'
				: 'metos-spray-forecast';
		const searchParams = new URLSearchParams();
		if (opts.service === 'metos') {
			searchParams.append('farm', String(opts.farmId));
			searchParams.append('station_id', String(opts.stationId));
		}
		if (opts.service === 'hortec_ileaf_data') {
			searchParams.append('hortec_station', String(opts.stationId));
		}
		const response = await this.http.get<WeatherSprayForecastResponse<T>>(
			`/weather/${path}?${searchParams.toString()}`
		);
		if (opts.service === 'hortec_ileaf_data') {
			return (
				response as WeatherSprayForecastResponse<'hortec_ileaf_data'>
			).flatMap((item) => {
				const time = new Date(item.time);
				const data = [];
				for (const [key, value] of Object.entries(item)) {
					if (key !== 'date' && key.startsWith('H')) {
						data.push({
							time: add(time, {
								hours: Number(key.replace('H', ''))
							}).getTime(),
							forecast: value as number
						});
					}
				}
				return data;
			}) as WeatherSprayForecast;
		}
		if (opts.service === 'metos') {
			return (response as WeatherSprayForecastResponse<'metos'>).map(
				(item) =>
					({
						time: new Date(item.date).getTime(),
						forecast: item.spray_recommendation
					}) satisfies WeatherSprayForecast[number]
			);
		}
		throw new Error('Invalid service');
	};
}
