import { useQuery } from '@tanstack/react-query';
import { useUser } from './use-user';
import { useApi } from '../api-query.context';
export function useLayerTimeline(props) {
    const { data: user } = useUser();
    const api = useApi();
    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [
            'layer-timeline',
            props.farmId,
            props.layerId,
            props.coordinates?.join(',')
        ],
        queryFn: async () => {
            if (!props.coordinates) {
                throw new Error('No coordinates');
            }
            const rawResult = await api.drilldown.timeline(props.farmId, 'point', props.layerId, {
                coordinates: props.coordinates
            });
            const min = Math.min(...rawResult.map((r) => r.value));
            const max = Math.max(...rawResult.map((r) => r.value));
            return {
                rawResult,
                meta: {
                    min,
                    max
                }
            };
        },
        enabled: !!props.coordinates && !!user
    });
}
