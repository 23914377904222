import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
export const filterOrder = [
    'my_soil_classification(0)',
    'itest_soil(0)',
    'itest_water(0)',
    'my_smart_layer(0)',
    'my_irrigation(0)',
    'ifly(0)',
    'itest_leaf(0)',
    "itest_farmer's_sample(0)",
    'itest_petiole(0)',
    'ppm(0)',
    'my_uploads(0)',
    'iconsult(0)',
    'my_yield(0)',
    'my_profitability(0)',
    'my_assets(0)',
    'my_infrastructure(0)',
    'my_growth(0)',
    'my_flight(0)'
];
export const useFilterGroupsOptions = (forappmenu) => {
    const api = useApi();
    const key = ['filterGroups', forappmenu];
    return queryOptions({
        queryKey: key,
        queryFn: () => {
            return api.filters.list(false, forappmenu !== undefined ? forappmenu : true);
        },
        select: toSortedFilterGroups
    });
};
export const useFilterGroups = (opts) => {
    const api = useApi();
    const key = ['filterGroups', opts?.forappmenu];
    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: key,
        queryFn: () => {
            return api.filters.list(false, opts?.forappmenu !== undefined ? opts.forappmenu : true);
        },
        select: (data) => {
            data = data
                .filter((f) => !f.coming_soon)
                .sort((a, b) => filterOrder.indexOf(a.unique_id) - filterOrder.indexOf(b.unique_id));
            if (opts?.select)
                return opts.select(data);
            return data;
        },
        staleTime: Infinity
    });
};
export function useFilterGroupByName(name) {
    const opts = useFilterGroupsOptions();
    return useQuery({
        ...opts,
        select: (data) => data.find((fg) => fg.name === name)
    });
}
export function toSortedFilterGroups(data) {
    return data.sort((a, b) => filterOrder.indexOf(a.unique_id) - filterOrder.indexOf(b.unique_id));
}
