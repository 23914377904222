import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
import { mergeFeatureCollections } from '@agritechnovation/utils';
export function useSoilClassLabels(availableLayerId, enabled = true) {
    const api = useApi();
    return useQuery({
        queryKey: ['soil-class-labels', availableLayerId],
        queryFn: () => {
            if (!availableLayerId)
                throw new Error('No available layer id provided');
            return api.soilclassification.labels(availableLayerId);
        },
        enabled: enabled && !!availableLayerId,
        retry: 3
    });
}
export function useMultipleSoilClassLabels(availableLayerIds, enabled = false) {
    const api = useApi();
    const queries = useQueries({
        queries: availableLayerIds.map((id) => {
            return queryOptions({
                queryKey: ['soil-class-labels', id],
                queryFn: () => api.soilclassification.labels(id),
                select: (data) => {
                    data.features = data.features.filter((f) => !!f.properties?.value);
                    return data;
                },
                enabled,
                retry: 3
            });
        }),
        combine: (results) => {
            const featureCollections = results
                .map((q) => q.data)
                .filter(Boolean);
            const merged = mergeFeatureCollections(...featureCollections);
            return {
                data: merged,
                isPending: results.some((q) => q.isPending),
                isError: results.some((q) => q.isError),
                isFetching: results.some((q) => q.isFetching),
                isSuccess: results.every((q) => q.isSuccess),
                error: results.map((q) => q.error).filter(Boolean)
            };
        }
    });
    return queries;
}
