import { queryOptions, useQuery } from '@tanstack/react-query';
import { useApi } from '../api-query.context';
import { isMyProfitabilityLayer } from './use-farm-layer-data';
export function farmLayerStyleQueryKey({ farmId, layerFilterId }) {
    return ['farm', farmId, 'layer', layerFilterId, 'style'];
}
export function farmLayerStyleQueryOptions(api, { farmId, layerFilter }) {
    return queryOptions({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps -- the query will only be enabled when layerFilter is defined
        queryKey: ['farm', farmId, 'layer', layerFilter?.id, 'style'],
        queryFn: async () => {
            if (!layerFilter) {
                throw new Error('layerFilter is required');
            }
            if (isMyProfitabilityLayer(layerFilter))
                return null;
            if (layerFilter.layer_type === 'cog') {
                const verdeType = layerFilter.layer_name.split('_').at(0);
                return api.availableLayers
                    .cogStyle(farmId, layerFilter.id, verdeType)
                    .catch(() => null);
            }
            return api.availableLayers.style(farmId, layerFilter.id);
        },
        enabled: !!layerFilter
    });
}
export function prefetchFarmLayerStyle({ api, queryClient }, { farmId, layerFilter }) {
    return queryClient.prefetchQuery(farmLayerStyleQueryOptions(api, { farmId, layerFilter }));
}
export function useFarmLayerStyle(options) {
    const api = useApi();
    return useQuery(farmLayerStyleQueryOptions(api, options));
}
export function useFarmLayerStyleIsLoading(options) {
    const { isFetching } = useFarmLayerStyle(options);
    return isFetching;
}
